<script setup>
import { ref } from "vue";

const messages = ["Calculating...", "Processing...", "Loading..."];
const currentMessage = ref(messages[0]);

let index = 0;
setInterval(() => {
  index = (index + 1) % messages?.length;
  currentMessage.value = messages[index];
}, 2000);
</script>

<template>
  <div class="flex justify-center">
    <p class="font-bold text-2xl md:text-5xl bg-gradient-to-r from-Periwinkle via-FlatBlue to-Ruddy bg-clip-text text-transparent py-4">{{ currentMessage }}</p>
  </div>
</template>