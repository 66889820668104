<template>
  <div
    class="h-[10%] w-full flex flex-col gap-4 md:flex-row md:items-start md:justify-between"
  >
    <!-- Title -->
    <h3
      v-if="!isLinkedAccount"
      class="font-semibold text-lg md:text-xl w-full md:w-1/6"
    >
      {{ title }}
    </h3>

    <div
      class="w-full flex flex-col items-start gap-6 md:flex-row md:items-center md:justify-between"
    >
      <!-- Left Section -->
      <div class="flex flex-wrap items-center gap-4">
        <!-- Test Type Dropdown-->
        <div class="relative rounded bg-ResolutionBlue px-3 py-2">
          <button
            @click="toggleTestTypeDropdown"
            class="flex min-w-56 w-full items-center justify-between gap-2.5"
          >
            <span class="text-xs font-bold text-white md:text-sm">
              {{ selectedTestType.name }}</span
            >
            <img :src="arrowDown" alt="arrow down" />
          </button>
          <DropdownWrapper v-if="showTestTypeDropdown">
            <div
              class="absolute left-[75%] top-full border-ResolutionBlue -translate-x-1/2 shadow-lg rounded-lg z-10 mt-1"
            >
              <ul
                class="w-40 min-w-fit text-xs text-OsloGrey rounded-lg md:text-sm"
              >
                <li
                  v-for="testType in testTypes"
                  :key="testType.type"
                  @click="setTestType(testType)"
                  class="w-full py-2 px-4 overflow-hidden transition-colors duration-300 hover:bg-ResolutionBlue hover:text-white cursor-pointer"
                  :class="
                    testType.type
                      ? 'bg-BlueChalk text-ResolutionBlue'
                      : 'bg-ResolutionBlue text-white'
                  "
                >
                  {{ testType.name }}
                </li>
              </ul>
            </div>
          </DropdownWrapper>
        </div>

        <!-- Filter Dropdown -->
        <div class="relative">
          <button
            @click="toggleFilterDropdown"
            class="flex items-center gap-2 px-4 py-2 border border-gray-300 bg-white rounded-lg hover:bg-gray-100"
          >
            <span class="text-sm">{{ selectedFilter }}</span>
            <img
              src="@/assets/icons/arrow_drop_down.svg"
              alt="arrow icon"
              class="w-4 h-4"
            />
          </button>
          <DropdownWrapper v-if="showFilterDropdown">
            <div
              class="absolute bg-white border border-gray-200 shadow-lg rounded-lg mt-2 z-10"
            >
              <ul class="min-w-[200px] text-sm">
                <li
                  v-for="filter in filters"
                  :key="filter"
                  @click="setFilter(filter)"
                  class="py-2 px-4 hover:bg-gray-200 cursor-pointer"
                >
                  {{ filter }}
                </li>
              </ul>
            </div>
          </DropdownWrapper>
        </div>
      </div>

      <!-- Right Section -->
      <div
        class="w-fit flex items-center gap-2 px-4 py-2 rounded-full text-xs bg-lightBlue text-ResolutionBlue"
      >
        <p>Target Range:</p>
        <p class="font-semibold">
          <template v-if="selectedTestType.type === 'glucose'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[500px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                Random blood sugar: 70-180mg/dl (3.9-10mmol/l)<br />
                2hr post meal: less than 140mg/dl (7.8mmol/l)<br />
                Fasting Blood Sugar: 70-100mg/dl (3.9-5.6mmol/l)<br />
                HbA1c: Normal 4% and 5.6%, Pre Diabetes 5.7%-6.4%, Diabetes:
                6.5%
              </div>
            </div>
          </template>
          <template v-else-if="selectedTestType.type === 'typhoid'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[300px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                Salmonella Typhi O (TO): >1:80 Titre<br />
                Salmonella Typhi H (TH): >1:160 Titre<br />
                Salmonella Paratyphi A,H (AH): >1:320 Titre<br />
                Salmonella Paratyphi B,H (BH): >1:320 Titre
              </div>
            </div>
          </template>

          <template v-else-if="selectedTestType.type === 'heart_rate'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[200px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                Blood Oxygen Saturation<br />
                Normal: 100%-98%<br />
                Tolerable: 97%-95%
              </div>
            </div>
          </template>
          <template v-else-if="selectedTestType.type === 'blood_pressure'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[400px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                <p>NORMAL: &lt; 120 and &lt; 80</p>
                <br />
                <p>ELEVATED: 120-129 and &lt; 80</p>
                <br />
                <p>HBP (HYPERTENSION) STAGE 1: 130-139 and 80-89</p>
                <br />
                <p>HBP (HYPERTENSION) STAGE 2: >=140 or >=90</p>
                <br />
                <p>HYPERTENSIVE CRISIS: &lt; 180 and/or &lt; 120</p>
                <br />
              </div>
            </div>
          </template>
          <template v-else-if="selectedTestType.type === 'lung_capacity'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[400px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                <p>
                  EXPECTED FEV1 = Race x 1.08 x [(0.0395 x Height) - (0.025 x
                  Age) - 2.6]
                </p>
              </div>
            </div>
          </template>
          <template v-else-if="selectedTestType.type === 'waist_circumference'">
            <div class="relative group inline-block">
              <img
                :src="InfoIcon"
                alt="Info Icon"
                class="cursor-pointer w-4 h-4"
              />
              <div
                class="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[400px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30 before:content-[''] before:absolute before:left-1/2 before:-top-2 before:transform before:border-8 before:border-transparent before:border-b-blue-900"
              >
                <p>NORMAL: Women- &lt;80, Men &lt;90</p>
                <br />
                <p>GRADE I CED: Women- 90-104, Men- 100-109</p>
                <br />
                <p>GRADE II CED: Women- 105-114, Men- 110-124</p>
                <br />
                <p>GRADE III CED: Women- ≥115, Men- ≥125</p>
                <br />
              </div>
            </div>
          </template>
          <template v-else>
            {{ selectedTestType.targetRange || "N/A" }}
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import DropdownWrapper from "../dropdowns/DropdownWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import arrowDown from "@/assets/icons/white-down-arrow.svg";
import { testTypes } from "@/utils/mockData/testTypes";
import InfoIcon from "@/assets/icons/info_outline.svg";

// Props
const props = defineProps({
  title: {
    type: String,
    default: "History",
  },
  selectedFilter: {
    type: String,
    required: true,
  },
  showFilterDropdown: {
    type: Boolean,
    required: true,
  },
  average_high: {
    type: Number,
    required: true,
  },
  selectedTestType: {
    type: Object,
    required: true,
  },
  showTestTypeDropdown: {
    type: Boolean,
    required: true,
  },
  isLinkedAccount: {
    type: Boolean,
    default: false,
  },
});

// Filter options array
const filters = [
  "All",
  "Today",
  "Yesterday",
  "Last week",
  "Last month",
  "Last year",
];

// Emits
const emit = defineEmits([
  "toggleFilterDropdown",
  "setFilter",
  "setTestType",
  "toggleTestTypeDropdown",
]);

// Methods to handle events
const toggleFilterDropdown = () => {
  emit("toggleFilterDropdown");
};

const setFilter = (filter) => {
  emit("setFilter", filter);
};

const toggleTestTypeDropdown = () => {
  emit("toggleTestTypeDropdown");
};

const setTestType = (testType) => {
  emit("setTestType", testType);
};
</script>
