<template>
  <section class="w-full flex flex-col gap-5">
    <div class="flex items-center gap-2">
      <label class="text-Cinder font-bold text-base">Analysis</label>
      <div class="relative group">
        <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
        <div
          class="w-[190px] absolute left-[116%] top-[-90%] bg-blue-900 text-white text-sm rounded-lg p-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-3 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
        >
          Select the abnormalities
        </div>
      </div>
    </div>

    <div
      class="w-full h-[226px] grid grid-cols-4 gap-2 py-5 px-3 border-2 border-ClearBlue rounded-xl overflow-y-auto"
    >
      <button
        v-for="abnormality in abnormalities"
        :key="abnormality"
        @click="toggleAbnormality(abnormality)"
        class="py-3 px-5 text-xs border rounded-xl transition-colors"
        :class="
          selectedAbnormalities.includes(abnormality)
            ? 'bg-ResolutionBlue text-white border-ResolutionBlue'
            : 'bg-mistGray text-Charcoal border-mistGray'
        "
      >
        {{ abnormality }}
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref, watch } from "vue";
import InfoIcon from "../../../../assets/icons/info_outline.svg";

const props = defineProps({
  modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);

const abnormalities = ref([
  "No abnormalities",
  "Missed beat",
  "Accidental VPB",
  "VPB trigeminy",
  "VPB bigeminy",
  "VPB couple",
  "VPB RonT",
  "VPB runs of 3",
  "VPB runs of 4",
  "Bradycardia",
  "Tachycardia",
  "Arrhythmia",
  "ST elevation",
  "ST depression",
  "Atrial fibrillation (AFib)",
  "Atrial flutter",
  "Supraventricular tachycardia (SVT)",
  "Ventricular tachycardia (VT)",
  "Ventricular fibrillation (VFib)",
  "Premature Atrial Contractions (PACs)",
  "Premature Ventricular Contractions (PVCs)",
  "Wolff-Parkinson-White (WPW) syndrome",
  "First-degree AV block",
  "Second-degree AV block (Type 1 - Wenckebach, Type 2 - Mobitz II)",
  "Third-degree (complete) AV block",
  "Right Bundle Branch Block (RBBB)",
  "Left Bundle Branch Block (LBBB)",
  "Acute Myocardial Infarction (MI) (STEMI, NSTEMI)",
  "Hyperacute T waves (early sign of MI), Pathological Q waves (old infarction)",
  "T-wave inversions (ischemia, pericarditis, electrolyte imbalances)",
  "Left Ventricular Hypertrophy (LVH)",
  "Right Ventricular Hypertrophy (RVH)",
  "Pericarditis (widespread ST elevation, PR depression)",
  "Hypokalemia (U waves, ST depression, prolonged QT)",
  "Hyperkalemia (tall peaked T waves, widened QRS)",
  "Hypocalcemia (prolonged QT interval)",
  "Hypercalcemia (shortened QT interval)",
  "Long QT Syndrome (risk for sudden cardiac death)",
  "Brugada Syndrome (ST elevation in V1-V3, risk for sudden death)",
]);

const selectedAbnormalities = ref(
  props.modelValue ? props.modelValue.split(", ") : []
);

const toggleAbnormality = (abnormality) => {
  const index = selectedAbnormalities.value.indexOf(abnormality);
  if (index === -1) {
    selectedAbnormalities.value.push(abnormality);
  } else {
    selectedAbnormalities.value.splice(index, 1);
  }
};

watch(
  selectedAbnormalities,
  (newVal) => {
    emit("update:modelValue", newVal.join(", "));
  },
  { deep: true }
);
</script>
