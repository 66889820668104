<template>
  <main
    class="mx-auto px-2 lg:px-16 w-full min-h-[calc(100vh-80px)] flex flex-col space-y-8"
  >
    <section class="w-full mx-auto bg-white p-4 md:p-10 rounded-lg shadow-xl">
      <div
        v-if="isLoading"
        class="w-full h-full flex justify-center items-center"
      >
        <div class="self-center flex justify-center items-center w-6 h-6">
          <SpinnerIcon />
        </div>
      </div>

      <ErrorMessage v-else-if="isError && !progressReports" />

      <template v-if="progressReports?.results?.length">
        <section
          class="w-full flex flex-col gap-4 items-start lg:flex-row md:gap-6"
        >
          <div
            class="relative md:max-w-[394px] w-full h-[364px] md:h-[479px] flex items-center justify-center bg-ClearGrey py-2 px-8 rounded-lg"
          >
            <img :src="Human" alt="human" class="h-full" />
            <div
              class="absolute top-10 right-10 w-fit p-2.5 flex items-center gap-1.5 bg-ResolutionBlue bg-opacity-5 rounded-l-full rounded-r-lg"
            >
              <div
                class="w-5 h-5 p-1 flex items-center justify-center bg-white rounded-full"
              >
                <img :src="weightIcon" alt="weight" />
              </div>
              <p class="text-xs text-Cinder font-semibold">Weight</p>
            </div>

            <div
              class="absolute bottom-10 left-10 w-fit p-2.5 flex items-center gap-1.5 bg-ResolutionBlue bg-opacity-5 rounded-r-full rounded-l-lg"
            >
              <div
                class="w-5 h-5 p-1 flex items-center justify-center bg-white rounded-full"
              >
                <img :src="bmiIcon" alt="weight" />
              </div>
              <p class="text-xs text-Cinder font-semibold">BMI</p>
            </div>
          </div>

          <section class="w-full flex flex-col items-start gap-4">
            <div class="w-full flex items-center justify-between">
              <h2 class="text-xl text-GunMetal font-bold">
                Monthly Progress Report
              </h2>

              <BaseButton
              v-if="progressReports?.results?.length !== 3"
                type="button"
                :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
                @click="openProgressGoalSelectModal"
              >
                Set a Goal
              </BaseButton>
            </div>

            <div class="w-full flex flex-col items-start gap-4 lg:flex-row">
              <div
                v-for="report in orderedReports"
                :key="report.id"
                :class="[
                  'w-full lg:w-fit flex flex-col gap-3 p-4 rounded-2xl',
                  report.parameter === 'weight'
                    ? 'bg-LightYelow'
                    : 'bg-blandPurple',
                ]"
              >
                <div class="w-full flex items-center justify-between">
                  <h4 class="text-OlsoGrey text-xs font-semibold">
                    {{ formatParameter(report.parameter) }}
                  </h4>
                  <div
                    class="w-6 h-6 p-1 flex items-center justify-center rounded-full border border-TurbhamGreen"
                  >
                    <img
                      :src="icons[report.parameter]"
                      :alt="`${report.parameter} icon`"
                    />
                  </div>
                </div>

                <h3 class="text-DarkJungle text-2xl font-bold">
                  {{ report.current_value || report.start_value }}
                  <span class="text-sm">{{report.unit || "kg/m²"}}</span>
                </h3>

                <div class="space-y-2">
                  <p class="text-DavyGrey text-[8px]">
                    Target Goal:
                    <span class="font-semibold">
                      {{ report.target_value }}{{report.unit || "kg/m²"}}
                    </span>
                  </p>
                  <p class="text-DavyGrey text-[8px]">
                    Last Updated:
                    <span class="font-semibold">
                      {{ formatDate(report.updated_at) }}
                    </span>
                  </p>
                  <p class="text-DavyGrey text-[8px]">
                    Progress Summary: {{ getProgressSummary(report) }}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
      </template>

      <div
        v-else-if="progressReports?.results?.length === 0"
        class="w-full flex flex-col items-center gap-6"
      >
        <div class="flex flex-col items-center gap-11">
          <div class="w-[120px] h-[120px]">
            <img :src="Wheel" alt="wheel icon" class="w-full h-full" />
          </div>

          <div class="space-y-4 text-center text-Cinder text-base">
            <h4 class="text-2xl font-bold">No Goals Set Yet</h4>
            <p>Kickstart your health journey by setting a goal today!</p>
          </div>
        </div>

        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
          @click="openProgressGoalSelectModal"
        >
          Set a Goal
        </BaseButton>
      </div>
    </section>
  </main>
</template>

<script setup>
import { ref, inject, computed } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useQuery } from "@tanstack/vue-query";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import Wheel from "@/assets/icons/circle-wheel.svg";
import weightIcon from "@/assets/icons/testTypes/scale.svg";
import waistCircIcon from "@/assets/icons/testTypes/womens belt.svg";
import bmiIcon from "@/assets/icons/testTypes/industrial scales.svg";
import Human from "@/assets/images/human-body.svg";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";

const { "user/getAllProgressReport": getAllProgressReport } = mapActions();

const openProgressGoalSelectModal = inject("openProgressGoalSelectModal");

const {
  data: progressReports,
  isLoading,
  isError,
} = useQuery({
  queryKey: ["progressReports"],
  queryFn: () => getAllProgressReport(),
});

const icons = {
  weight: weightIcon,
  "waist circumference": waistCircIcon,
  bmi: bmiIcon,
};

const orderedReports = computed(() => {
  if (!progressReports.value?.results) return [];
  const order = ["weight", "waist_circumference", "bmi"];
  return [...progressReports.value.results].sort(
    (a, b) => order.indexOf(a.parameter) - order.indexOf(b.parameter)
  );
});

const formatParameter = (param) => {
  switch (param) {
    case "weight":
      return "Weight";
    case "waist_circumference":
      return "Waist";
    case "bmi":
      return "BMI";
    default:
      return param;
  }
};

const getUnit = (param) => {
  switch (param) {
    case "weight":
      return " KG";
    case "waist_circumference":
      return "CM";
    case "bmi":
      return "kg/m²";
    default:
      return "";
  }
};

const formatDate = (dateString) => {
  if (!dateString) return "N/A";
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  } catch {
    return "N/A";
  }
};

const getProgressSummary = (report) => {
  if (report.goal === "lose") {
    return `Good progress! Maintain your current routine to achieve ${
      report.target_value
    }${report.unit || "kg/m²"} target.`;
  }
  return `Keep pushing! You're on track to reach ${
    report.target_value
  }${report.unit || "kg/m²"} goal.`;
};
</script>
