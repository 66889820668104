<template>
  <svg
    width="2"
    height="20"
    viewBox="0 0 2 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="20" rx="1" fill="#011D7E" />
  </svg>
</template>
