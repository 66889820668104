<template>
  <div class="flex justify-between mb-6">
    <div class="bg-Seashell flex space-x-4 p-2 rounded-lg">
      <BaseButton
        class="filter-btn"
        :class="{ active: filter === 'all' }"
        @click="$emit('setNotificationFilter', 'all')"
      >
        All <span class="count">{{ displayCount("allNotifications") }}</span>
      </BaseButton>
      <BaseButton
        class="filter-btn"
        :class="{ active: filter === 'alert' }"
        @click="$emit('setNotificationFilter', 'alert')"
      >
        Alert <span class="count">{{ displayCount("alerts") }}</span>
      </BaseButton>
      <BaseButton
        class="filter-btn"
        :class="{ active: filter === 'announcement' }"
        @click="$emit('setNotificationFilter', 'announcement')"
      >
        Announcement
        <span class="count">{{ displayCount("announcements") }}</span>
      </BaseButton>
      <BaseButton
        class="filter-btn"
        :class="{ active: filter === 'reminder' }"
        @click="$emit('setNotificationFilter', 'reminder')"
      >
        Reminders <span class="count">{{ displayCount("reminders") }}</span>
      </BaseButton>
      <BaseButton
        class="filter-btn"
        :class="{ active: filter === 'test' }"
        @click="$emit('setNotificationFilter', 'test')"
      >
        Tests <span class="count">{{ displayCount("tests") }}</span>
      </BaseButton>
    </div>
  </div>
</template>

<script setup>
import { mapGetters } from "@/hooks/mapStore";
import BaseButton from "@/components/main/ui/BaseButton.vue";

const { "notifications/getNotificationCounts": notificationCounts } =
  mapGetters();

defineProps({
  filter: {
    type: String,
  },
});

defineEmits(["setNotificationFilter"]);

const displayCount = (type) => {
  return notificationCounts.value[type];
};
</script>

<style scoped>
.filter-btn {
  @apply bg-Seashell flex items-center font-medium py-2 px-4 border-none rounded-lg text-gray-700 focus:outline-none;
}

.filter-btn.active {
  @apply bg-white text-blue-900 font-bold;
}

.count {
  @apply ml-2 py-1 px-2 rounded-full bg-gray-300 text-xs font-semibold;
}
</style>
