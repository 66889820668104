<template>
  <div>
    <!-- Blood Glucose Specific Fields -->
    <div v-if="type === 'Glucose' || type === 'Blood Glucose'">
      <div
        class="max-w-[450px] w-full mx-auto flex items-center gap-2.5 py-1 px-2 rounded-full bg-BlueChalk self-center"
      >
        <button
          @click="setActiveTab('fasting_blood_sugar')"
          :class="buttonClass('fasting_blood_sugar')"
          :disabled="isAutoMode"
        >
          Fasting blood sugar
        </button>
        <button
          @click="setActiveTab('random_blood_sugar')"
          :class="buttonClass('random_blood_sugar')"
          :disabled="isAutoMode"
        >
          Random blood sugar
        </button>
        <button
          @click="setActiveTab('2hr_post_meal')"
          :class="buttonClass('2hr_post_meal')"
          :disabled="isAutoMode"
        >
          2hr post meal
        </button>
        <button
          @click="setActiveTab('HbA1c')"
          :class="buttonClass('HbA1c')"
          :disabled="isAutoMode"
        >
          HbA1c
        </button>
      </div>
    </div>

    <!-- Blood Pressure Specific Fields -->
    <div
      v-else-if="type === 'Blood Pressure'"
      class="grid grid-cols-2 gap-4 text-BluishGrey text-sm"
    >
      <div class="flex flex-col gap-2 items-start w-full">
        <label class="text-SkyGrey font-medium text-sm">Pulse Rate</label>
        <input
          v-model="additionalFields.pulseRate"
          :disabled="isAutoMode"
          type="text"
          placeholder=""
          class="pb-2 w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
        />
      </div>
      <div class="flex flex-col gap-2 items-start w-full">
        <label class="text-SkyGrey font-medium text-sm">PAD Detected</label>
        <div class="relative w-full">
          <div
            @click="togglePadDropdown"
            class="w-full flex items-center justify-between pb-2 text-sm cursor-pointer border-b border-BlueChalk"
          >
            <span>
              {{
                additionalFields.padDetected === 1
                  ? "Yes"
                  : additionalFields.padDetected === 0
                  ? "No"
                  : "--"
              }}
            </span>
            <img
              :src="dropdownVisible ? WhiteDownArrow : DownArrow"
              alt="dropdown arrow"
              class="w-4 h-4"
            />
          </div>

          <!-- Dropdown options -->
          <div
            v-if="dropdownVisible"
            class="absolute top-full left-0 mt-1 w-full bg-white border border-BlueChalk rounded-lg shadow-lg z-50"
          >
            <div
              v-for="option in padDropdownOptions"
              :key="option"
              @click="selectPadOption(option)"
              class="px-4 py-2 cursor-pointer whitespace-nowrap"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Temperature Result Specific Fields -->
    <div v-else-if="type === 'Temperature'">
      <div class="flex flex-col items-start gap-2 w-full">
        <label class="text-SkyGrey font-medium text-sm">Description</label>
        <input
          v-model="additionalFields.description"
          :disabled="isAutoMode"
          type="text"
          placeholder=""
          class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
        />
      </div>
    </div>

    <!-- Lung Capacity Specific Fields -->
    <div
      v-else-if="type === 'Lungs capacity' || type === 'Lungs Capacity'"
      class="w-full flex flex-col gap-6"
    >
      <div class="flex flex-col items-start gap-2 w-full">
        <div class="flex items-center gap-2">
          <label class="text-SkyGrey font-medium text-sm">PEF</label>
          <div class="relative group">
            <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
            <div
              class="w-[190px] absolute left-[116%] top-[-90%] bg-blue-900 text-white text-sm rounded-lg p-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-3 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
            >
              Peak Expiratory Flow
            </div>
          </div>
        </div>
        <input
          v-model="additionalFields.pef"
          :disabled="isAutoMode"
          type="text"
          placeholder=""
          class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
        />
      </div>

      <section class="w-full flex items-center gap-5">
        <div class="flex flex-col items-start gap-2 w-full">
          <div class="flex items-center gap-2">
            <label class="text-SkyGrey font-medium text-sm">FEV1</label>
            <div class="relative group">
              <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
              <div
                class="w-[190px] absolute left-[116%] top-[-90%] bg-blue-900 text-white text-sm rounded-lg p-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-3 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
              >
                Forced Expiratory Volume 1
              </div>
            </div>
          </div>

          <input
            v-model="additionalFields.fev1"
            :disabled="isAutoMode"
            type="text"
            placeholder=""
            class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
          />
        </div>

        <div class="flex flex-col items-start gap-2 w-full">
          <div class="flex items-center gap-2">
            <label class="text-SkyGrey font-medium text-sm">FEV2</label>
            <div class="relative group">
              <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
              <div
                class="w-[200px] absolute left-[116%] top-[-90%] bg-blue-900 text-white text-sm rounded-lg p-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-3 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
              >
                Forced Expiratory Volume 2
              </div>
            </div>
          </div>

          <input
            v-model="additionalFields.fev2"
            :disabled="isAutoMode"
            type="text"
            placeholder=""
            class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
          />
        </div>

        <div class="flex flex-col items-start gap-2 w-full">
          <div class="flex items-center gap-2">
            <label class="text-SkyGrey font-medium text-sm">FEV3</label>
            <div class="relative group">
              <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
              <div
                class="w-[200px] absolute left-[116%] top-[-90%] bg-blue-900 text-white text-sm rounded-lg p-3 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-3 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
              >
                Forced Expiratory Volume 3
              </div>
            </div>
          </div>

          <input
            v-model="additionalFields.fev3"
            :disabled="isAutoMode"
            type="text"
            placeholder=""
            class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
          />
        </div>
      </section>
    </div>

    <!-- Heart Rate Specific Fields -->
    <div
      v-else-if="type === 'Spo2' || type === 'Heart Rate'"
      class="grid grid-cols-2 gap-4 text-BluishGrey text-sm"
    >
      <div class="flex flex-col gap-2 items-start w-full">
        <label class="text-SkyGrey font-medium text-sm">Pulse Rate</label>
        <input
          v-model="additionalFields.pulseRate"
          :disabled="isAutoMode"
          type="number"
          placeholder=""
          class="w-full pb-2 border-b border-b-BlueChalk text-sm font-semibold outline-none"
        />
      </div>
      <div class="flex flex-col gap-2 items-start">
        <label class="text-SkyGrey font-medium text-sm">Perfusion Index</label>
        <input
          v-model="additionalFields.perfusionIndex"
          :disabled="isAutoMode"
          type="number"
          placeholder=""
          class="w-full pb-2 border-b border-b-BlueChalk text-sm outline-none"
        />
      </div>
    </div>

    <div v-else-if="type === 'ECG'" class="flex flex-col gap-6">
      <div class="flex flex-col items-start gap-3 w-full">
        <label class="text-SkyGrey font-medium text-sm">Heart Rate</label>
        <input
          v-model="additionalFields.heart_rate"
          :disabled="isAutoMode"
          type="text"
          placeholder="Type your heart rate"
          class="w-full font-semibold border-b border-b-BlueChalk text-sm outline-none"
        />
      </div>
      <div class="flex flex-col items-start gap-3 w-full">
        <label class="text-SkyGrey font-medium text-sm">Note</label>
        <textarea
          v-model="additionalFields.note"
          :disabled="isAutoMode"
          type="text"
          placeholder="Type a note"
          class="w-full min-h-[100px] p-2 font-semibold border border-b-BlueChalk text-sm outline-none resize-none"
        />
      </div>
    </div>

    <!-- Malaria Specific Fields -->
    <div v-else-if="type === 'Malaria'">
      <div class="flex flex-col items-start gap-3 w-full">
        <label class="text-SkyGrey font-medium text-sm">Note</label>
        <textarea
          v-model="additionalFields.malaria_note"
          :disabled="isAutoMode"
          type="text"
          placeholder=""
          class="w-full min-h-[164px] p-4 font-semibold border border-ClearBlue text-sm text-black rounded-xl outline-none"
        ></textarea>
      </div>
    </div>

    <!-- Widal Specific Fields -->
    <div
      v-if="type === 'Widal Test' || type === 'Widal'"
      class="max-w-[824px] w-full flex flex-col gap-[50px]"
    >
      <div class="flex flex-col py-3 border border-ClearBlue rounded-xl">
        <div
          v-for="(test, index) in widalTests"
          :key="index"
          class="flex items-center justify-between p-4 rounded-md"
        >
          <span class="font-bold text-2xl text-Cinder">{{
            test.testName
          }}</span>

          <div class="relative" :ref="setDropdownRef(index)">
            <div
              @click="toggleDropdown(index)"
              class="min-w-[134px] w-full flex items-center justify-between px-3 py-2.5 text-sm font-bold rounded-full cursor-pointer"
              :class="{
                'bg-ResolutionBlue text-white': test.testResult,
                'bg-Geyser text-MistBlue': !test.testResult,
              }"
            >
              <span>{{ test.testResult || "Select Type" }}</span>
              <img
                :src="test.testResult ? WhiteDownArrow : DownArrow"
                alt="dropdown icon"
                class="w-4 h-4"
              />
            </div>

            <!-- Dropdown Options -->
            <div
              v-if="dropdownIndex === index"
              class="absolute top-full left-0 w-full mt-1 bg-white border border-BlueChalk rounded-md shadow-lg z-50"
            >
              <div
                v-for="option in dropdownOptions"
                :key="option"
                @click="selectOption(index, option)"
                class="px-4 py-2 cursor-pointer whitespace-nowrap"
              >
                {{ option }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Display Abbreviations Below -->
      <WidalTestsAbrevs />
    </div>

    <!-- Hepatatis B Specific Fields -->
    <div
      v-if="type === 'Hepatitis B' || type === 'Hepatitis_B'"
      class="max-w-[824px] w-full flex flex-col py-3 border border-ClearBlue rounded-xl"
    >
      <div class="flex items-center justify-between p-4 rounded-md">
        <span class="font-bold text-2xl text-Cinder">Hepatitis B</span>

        <div class="relative">
          <div
            @click="toggleHepatitisDropdown"
            class="min-w-[134px] w-full flex items-center justify-between px-3 py-2.5 text-sm font-bold rounded-full cursor-pointer"
            :class="{
              'bg-ResolutionBlue text-white': hepatitisResult.value,
              'bg-Geyser text-MistBlue': !hepatitisResult.value,
            }"
          >
            <span>{{ hepatitisResult.value || "Select Type" }}</span>
            <img
              :src="hepatitisResult.value ? WhiteDownArrow : DownArrow"
              alt="dropdown icon"
              class="w-4 h-4"
            />
          </div>

          <!-- Dropdown Options -->
          <div
            v-if="dropdownIndex === 0"
            class="absolute top-full left-0 w-full mt-1 bg-white border border-BlueChalk rounded-md shadow-lg z-50"
          >
            <div
              v-for="option in hepatitisDropdownOptions"
              :key="option"
              @click="selectHepatitisOption(option)"
              class="px-4 py-2 cursor-pointer whitespace-nowrap"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Voluntary Serology Specific Fields -->
    <div
      v-if="type === 'Voluntary Serology' || type === 'HIV'"
      class="max-w-[824px] w-full flex flex-col py-3 border border-ClearBlue rounded-xl"
    >
      <div class="flex items-center justify-between p-4 rounded-md">
        <span class="font-bold text-2xl text-Cinder">Voluntary Serology</span>

        <div class="relative">
          <div
            @click="toggleHivDropdown"
            class="min-w-[134px] w-full flex items-center justify-between px-3 py-2.5 text-sm font-bold rounded-full cursor-pointer"
            :class="{
              'bg-ResolutionBlue text-white': hivResult.value,
              'bg-Geyser text-MistBlue': !hivResult.value,
            }"
          >
            <span>{{ hivResult.value || "Select Type" }}</span>
            <img
              :src="hivResult.value ? WhiteDownArrow : DownArrow"
              alt="dropdown icon"
              class="w-4 h-4"
            />
          </div>

          <!-- Dropdown Options -->
          <div
            v-if="dropdownIndex === 0"
            class="absolute top-full left-0 w-full mt-1 bg-white border border-BlueChalk rounded-md shadow-lg z-50"
          >
            <div
              v-for="option in hivDropdownOptions"
              :key="option"
              @click="selectHivOption(option)"
              class="px-4 py-2 cursor-pointer whitespace-nowrap"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import DownArrow from "../../../../assets/icons/grey-chevron-down.svg";
import WhiteDownArrow from "../../../../assets/icons/white-chevron-down.svg";
import InfoIcon from "../../../../assets/icons/info_outline.svg";
import WidalTestsAbrevs from "./WidalTestsAbrevs.vue";

const props = defineProps({
  type: {
    type: String,
    default: "",
  },
  widalTests: Array,
  isAutoMode: Boolean,
  additionalFields: Object,
  setActiveTab: Function,
  activeTab: String,
  hepatitisResult: {
    type: Object,
    required: true,
  },
  hivResult: {
    type: Object,
    required: true,
  },
});

const buttonClass = (tab) =>
  [
    props.activeTab === tab ? "bg-AsignGreen text-white" : "text-Gravel",
    "text-xs font-normal py-1.5 px-2.5 rounded-full",
    props.isAutoMode ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
  ].join(" ");

const dropdownIndex = ref(null);
const dropdownVisible = ref(false);

const dropdownOptions = ["Reactive", "Non-Reactive"];
const hepatitisDropdownOptions = ["HEP B+", "HEP B-"];
const hivDropdownOptions = ["HIV +", "HIV -"];
const padDropdownOptions = ["Yes", "No"];
const dropdownRefs = ref([]);
const hepatitisDropdownRef = ref(null);
const hivDropdownRef = ref(null);

// Dynamically assign refs to dropdown containers
const setDropdownRef = (index) => (el) => {
  dropdownRefs.value[index] = el;
};

// Toggle dropdown visibility
const toggleDropdown = (index) => {
  dropdownIndex.value = dropdownIndex.value === index ? null : index;
};

const toggleHepatitisDropdown = () => {
  dropdownIndex.value = dropdownIndex.value === 0 ? null : 0;
};

const toggleHivDropdown = () => {
  dropdownIndex.value = dropdownIndex.value === 0 ? null : 0;
};

const togglePadDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

// Select an option from the dropdown
const selectOption = (index, option) => {
  props.widalTests[index].testResult = option; // Update the test result
  dropdownIndex.value = null; // Close the dropdown
};

// Select an option for Hepatitis B
const selectHepatitisOption = (option) => {
  props.hepatitisResult.value = option;
  dropdownIndex.value = null;
};

const selectHivOption = (option) => {
  props.hivResult.value = option;
  dropdownIndex.value = null;
};

const selectPadOption = (option) => {
  props.additionalFields.padDetected = option === "Yes" ? 1 : 0;
  dropdownVisible.value = false; // Close dropdown
};

// Close dropdown on outside click
const handleClickOutside = (event, dropdownRef) => {
  const dropdown = dropdownRef || dropdownRefs.value[dropdownIndex.value];
  if (dropdown && !dropdown.contains(event.target)) {
    dropdownIndex.value = null;
  }
};

const closeDropdownOnOutsideClick = (event) => {
  if (!event.target.closest(".relative")) {
    dropdownVisible.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", (event) => handleClickOutside(event));
  document.addEventListener("click", (event) =>
    handleClickOutside(event, hepatitisDropdownRef.value)
  );
  document.addEventListener("click", (event) =>
    handleClickOutside(event, hivDropdownRef.value)
  );
  document.addEventListener("click", closeDropdownOnOutsideClick);
});

onUnmounted(() => {
  document.removeEventListener("click", (event) => handleClickOutside(event));
  document.removeEventListener("click", (event) =>
    handleClickOutside(event, hepatitisDropdownRef.value)
  );
  document.addEventListener("click", (event) =>
    handleClickOutside(event, hivDropdownRef.value)
  );
  document.removeEventListener("click", closeDropdownOnOutsideClick);
});
</script>
