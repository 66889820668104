<template>
  <ModalWrapper>
    <section
      class="max-w-3xl w-full p-10 flex flex-col gap-8 bg-white relative rounded-3xl"
    >
      <div class="w-full flex items-center justify-between">
        <h2 class="text-xl font-bold text-GunMetal">Goal Summary</h2>
        <div class="cursor-pointer" @click="closeModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <section class="w-full px-5 py-8 bg-WhiteLilac rounded-lg">
        <div
          v-if="isLoadingGoalData"
          class="self-center flex justify-center items-center w-6 h-6"
        >
          <SpinnerIcon />
        </div>

        <ErrorMessage v-else-if="isGoalDataError && !isLoadingGoalData" />

        <div
          v-else
          class="w-full flex flex-col gap-2.5 p-4 bg-white border border-NewBlack rounded-xl"
        >
          <!-- Goal Type Section -->
          <div
            v-if="goalData && goalData.parameter"
            class="w-full flex items-center justify-between"
          >
            <h4 class="text-xl text-GunMetal font-bold">
              {{ formattedParameter }}
            </h4>

            <img :src="EditIcon" alt="edit icon" />
          </div>

          <section
            class="w-full flex flex-col items-center gap-[30px] relative"
          >
            <!-- Target and Current Values -->
            <div class="w-[271px] h-[200px] absolute top-[-4%] left-1/5">
              <img :src="HalfRing" alt="half-ring" class="w-full h-full" />
            </div>
            <div class="flex flex-col items-center text-center gap-1 py-6">
              <p
                class="max-w-[120px] text-sm text-DavyGrey text-center font-medium"
              >
                Target {{ formattedParameter }}
              </p>
              <h4 class="text-2xl text-Cinder font-bold">
                {{ goalData?.target_value || "" }} {{ goalData?.unit || "" }}
              </h4>
            </div>

            <div class="w-full flex flex-col gap-3">
              <!-- Target Information -->
              <div
                class="w-full grid grid-cols-4 gap-5 items-start justify-center"
              >
                <div
                  v-for="(item, index) in itemsToDisplay"
                  :key="index"
                  class="flex flex-col items-center gap-4 px-4"
                >
                  <img
                    :src="item.icon"
                    :alt="item.label"
                    class="p-4 bg-GrayWhite rounded-full"
                  />
                  <div class="flex flex-col gap-1 items-center">
                    <p class="text-DavyGrey text-[10px] font-medium">
                      {{ item.label }}
                    </p>
                    <p class="text-xs font-semibold text-Cinder">
                      {{ item.value }} {{ item.unit }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Motivational Message -->
            <div
              class="w-full flex flex-col gap-[18px] p-4 text-Cinder text-xs font-medium border border-WhiteLilac rounded-lg"
            >
              <p>
                {{ firstName }}, great job! Just {{ daysRemaining }} days to go,
                you're so close! Keep your eyes on the goal, stay focused, and
                keep pushing. You've got this!
              </p>

              <div
                v-if="hasMedals"
                class="w-full flex flex-col gap-1 py-3 px-6 bg-ResolutionBlue rounded-xl"
              >
                <div class="w-6 h-6 self-end">
                  <img :src="InfoIcon" alt="info icon" class="w-full h-full" />
                </div>

                <div class="w-full flex items-start justify-between">
                  <div
                    v-for="(medal, index) in goalData.medal.weight_medals"
                    :key="index"
                    class="flex flex-col items-center gap-1 text-white text-base font-medium"
                  >
                    <p>{{ medal.medal_type }}</p>

                    <div class="w-10 h-9">
                      <img
                        :src="getMedalImage(medal.medal_type)"
                        :alt="`${medal.medal_type} award`"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="w-fit flex items-center justify-center">
       
        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
          @click="closeModal"
        >
          Continue
        </BaseButton>
      </div>
    </section>
  </ModalWrapper>
</template>

<script setup>
import { ref, computed, inject, watch, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useQuery } from "@tanstack/vue-query";
import ModalWrapper from "../../testCenter/modals/ModalWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import EditIcon from "@/assets/icons/edit-icon.svg";
import HalfRing from "@/assets/icons/green-half-ring.svg";
import Barbell from "@/assets/icons/barbell.svg";
import Clock from "@/assets/icons/clock-icon.svg";
import Calendar from "@/assets/icons/calendar-icon.svg";
import BronzeAward from "@/assets/icons/bronze-award.svg";
import GreenAward from "@/assets/icons/green-award.svg";
import GoldAward from "@/assets/icons/gold-award.svg";
import SilverAward from "@/assets/icons/silver-award.svg";
import InfoIcon from "@/assets/icons/white-info-icon.svg";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";

const props = defineProps(["goalId"]);

const { "user/getProgressGoalById": getProgressGoalById } = mapActions();

const firstName = computed(() => {
  return goalData.value?.patient?.full_name || "User";
});

const hasMedals = computed(() => {
  return goalData.value?.medal?.weight_medals?.length > 0;
});

const getMedalImage = (medalType) => {
  switch (medalType) {
    case "25%":
      return BronzeAward;
    case "50%":
      return SilverAward;
    case "75%":
      return GoldAward;
    case "100%":
      return GreenAward;
    default:
      return BronzeAward;
  }
};

// Date/Time formatting helper
const formatDate = (dateString, type) => {
  if (!dateString) return "N/A";
  const date = new Date(dateString);
  try {
    if (type === "date") {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    }
    if (type === "time") {
      return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  } catch {
    return "N/A";
  }
};

const daysRemaining = computed(() => {
  if (!goalData.value?.end_date) return 0;
  const end = new Date(goalData.value.end_date);
  const now = new Date();
  return Math.max(0, Math.ceil((end - now) / 86400000)); // 86400000 = 1 day in ms
});

const formattedParameter = computed(() => {
  switch (goalData.value?.parameter) {
    case "weight":
      return "Weight";
    case "bmi":
      return "BMI";
    case "waist_circumference":
      return "Waist Circumference";
    default:
      return goalData.value?.parameter;
  }
});

const {
  data: goalData,
  isLoading: isLoadingGoalData,
  isError: isGoalDataError,
} = useQuery({
  queryKey: ["progressGoalById", props.goalId],
  queryFn: () => getProgressGoalById(props.goalId),
  enabled: !!props.goalId, // Only fetch when goalId exists
});

const itemsToDisplay = computed(() => [
  {
    icon: Barbell,
    label: `Current ${formattedParameter.value}`,
    value: goalData.value?.start_value || "N/A",
    unit: goalData.value?.unit || "KG",
  },
  {
    icon: Clock,
    label: "Reminder",
    value: goalData.value?.reminder_interval || "N/A",
    unit: "hours",
  },
  {
    icon: Calendar,
    label: "Start Date",
    value: formatDate(goalData.value?.start_date, "date"),
    unit: "",
  },
  {
    icon: Clock,
    label: "Start Time",
    value: formatDate(goalData.value?.start_date, "time"),
    unit: "",
  },
  {
    icon: Calendar,
    label: "End Date",
    value: formatDate(goalData.value?.end_date, "date"),
    unit: "",
  },
  {
    icon: Clock,
    label: "End Time",
    value: formatDate(goalData.value?.end_date, "time"),
    unit: "",
  },
  {
    icon: Clock,
    label: "Goal",
    value: goalData.value?.goal === "lose" ? "Weight Loss" : "Weight Gain",
    unit: "",
  },
]);

// Modal handling
const closeGoalSummaryModal = inject("closeGoalSummaryModal");
const closeModal = () => {
  closeGoalSummaryModal();
};
</script>
