<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="3 User">
      <path
        id="Stroke 1"
        d="M11.9258 5.26416C12.8558 5.13349 13.5718 4.33616 13.5738 3.37016C13.5738 2.41816 12.8798 1.62883 11.9698 1.47949"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Stroke 3"
        d="M13.1523 7.5C14.053 7.63467 14.6817 7.95 14.6817 8.6C14.6817 9.04733 14.3857 9.338 13.907 9.52067"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Stroke 5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.92481 7.77539C5.78215 7.77539 3.95215 8.10006 3.95215 9.39672C3.95215 10.6927 5.77082 11.0267 7.92481 11.0267C10.0675 11.0267 11.8968 10.7054 11.8968 9.40806C11.8968 8.11072 10.0788 7.77539 7.92481 7.77539Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Stroke 7"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.92492 5.92501C9.33092 5.92501 10.4709 4.78567 10.4709 3.37901C10.4709 1.97301 9.33092 0.833008 7.92492 0.833008C6.51892 0.833008 5.37893 1.97301 5.37893 3.37901C5.37359 4.78034 6.50493 5.92034 7.90626 5.92501H7.92492Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Stroke 9"
        d="M3.92339 5.26416C2.99272 5.13349 2.27739 4.33616 2.27539 3.37016C2.27539 2.41816 2.96939 1.62883 3.87939 1.47949"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Stroke 11"
        d="M2.69633 7.5C1.79566 7.63467 1.16699 7.95 1.16699 8.6C1.16699 9.04733 1.46299 9.338 1.94166 9.52067"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
