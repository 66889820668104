<template>
  <div class="relative w-full">
    <div
      class="w-full flex items-center justify-between py-2.5 border-b border-BlueChalk cursor-pointer"
      @click="toggleDropdown"
    >
      <p class="text-DarkJungle text-sm font-bold">{{ selectedLabel || placeholder }}</p>
      <img
        :src="DropdownArrow"
        class="w-4 h-4 transition-transform"
        :class="{ 'rotate-180': isOpen }"
      />
    </div>

    <div
      v-show="isOpen"
      class="absolute top-full left-0 w-full mt-1 bg-white border border-BlueChalk rounded-lg shadow-lg z-10"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="p-3 hover:bg-WhiteLilac cursor-pointer transition-colors"
        @click="selectOption(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import DropdownArrow from "@/assets/icons/down_caret.svg";

const props = defineProps({
  modelValue: [String, Number],
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: String,
});

const emit = defineEmits(["update:modelValue"]);

const isOpen = ref(false);

const selectedLabel = computed(() => {
  return props.options.find((opt) => opt.value === props.modelValue)?.label;
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  emit("update:modelValue", option.value);
  isOpen.value = false;
};
</script>
