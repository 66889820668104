<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5041 4.26479L12.1151 3.58973C11.786 3.01853 11.0567 2.82148 10.4847 3.14923V3.14923C10.2124 3.30962 9.88752 3.35513 9.58166 3.27571C9.2758 3.19629 9.01409 2.99848 8.85424 2.72589C8.75141 2.55262 8.69615 2.35528 8.69406 2.15381V2.15381C8.70334 1.83079 8.58148 1.51778 8.35623 1.28607C8.13099 1.05437 7.82155 0.923695 7.49841 0.923828H6.71466C6.39807 0.923825 6.09454 1.04998 5.87122 1.27437C5.6479 1.49877 5.5232 1.8029 5.52473 2.11948V2.11948C5.51534 2.77311 4.98277 3.29804 4.32907 3.29797C4.1276 3.29588 3.93025 3.24062 3.75699 3.13779V3.13779C3.18501 2.81004 2.45566 3.00709 2.12656 3.57829L1.70894 4.26479C1.38023 4.83528 1.57459 5.56416 2.14372 5.89522V5.89522C2.51366 6.10881 2.74155 6.50352 2.74155 6.93069C2.74155 7.35786 2.51366 7.75258 2.14372 7.96616V7.96616C1.57532 8.29499 1.38074 9.02211 1.70894 9.59088V9.59088L2.10367 10.2717C2.25787 10.5499 2.5166 10.7552 2.82259 10.8422C3.12858 10.9291 3.45662 10.8906 3.73411 10.735V10.735C4.00689 10.5759 4.33196 10.5323 4.63706 10.6139C4.94215 10.6955 5.20199 10.8957 5.35882 11.1698C5.46166 11.3431 5.51691 11.5404 5.519 11.7419V11.7419C5.519 12.4022 6.05432 12.9376 6.71466 12.9376H7.49841C8.15652 12.9376 8.69091 12.4057 8.69406 11.7476V11.7476C8.69253 11.4301 8.81801 11.125 9.04257 10.9005C9.26713 10.6759 9.57214 10.5504 9.88971 10.552C10.0907 10.5574 10.2872 10.6124 10.4618 10.7122V10.7122C11.0323 11.0409 11.7612 10.8465 12.0922 10.2774V10.2774L12.5041 9.59088C12.6636 9.31722 12.7073 8.99129 12.6257 8.68526C12.5441 8.37924 12.3439 8.11838 12.0693 7.96044V7.96044C11.7948 7.8025 11.5946 7.54164 11.513 7.23562C11.4314 6.9296 11.4751 6.60367 11.6346 6.33001C11.7382 6.14899 11.8883 5.9989 12.0693 5.89522V5.89522C12.6351 5.56434 12.829 4.83971 12.5041 4.27051V4.27051V4.26479Z"
      stroke="#808D9E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="7.10951"
      cy="6.9308"
      r="1.6476"
      stroke="#808D9E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
