<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6014 8.0535C12.8399 8.18016 13.0238 8.38016 13.1533 8.58016C13.4053 8.9935 13.3849 9.50016 13.1396 9.94683L12.6627 10.7468C12.4106 11.1735 11.9405 11.4402 11.4568 11.4402C11.2184 11.4402 10.9526 11.3735 10.7346 11.2402C10.5575 11.1268 10.3531 11.0868 10.1351 11.0868C9.46058 11.0868 8.8951 11.6402 8.87466 12.3002C8.87466 13.0668 8.24786 13.6668 7.46436 13.6668H6.53779C5.74747 13.6668 5.12067 13.0668 5.12067 12.3002C5.10705 11.6402 4.54156 11.0868 3.86707 11.0868C3.64224 11.0868 3.43785 11.1268 3.26752 11.2402C3.04951 11.3735 2.77698 11.4402 2.54534 11.4402C2.0548 11.4402 1.5847 11.1735 1.33262 10.7468L0.862517 9.94683C0.610434 9.5135 0.596808 8.9935 0.848891 8.58016C0.9579 8.38016 1.16229 8.18016 1.39393 8.0535C1.5847 7.96016 1.70734 7.80683 1.82316 7.62683C2.16381 7.0535 1.95942 6.30016 1.38031 5.96016C0.705817 5.58016 0.487799 4.7335 0.876143 4.0735L1.33262 3.28683C1.72777 2.62683 2.57259 2.3935 3.2539 2.78016C3.84663 3.10016 4.61651 2.88683 4.96397 2.32016C5.07298 2.1335 5.1343 1.9335 5.12067 1.7335C5.10705 1.4735 5.18199 1.22683 5.31144 1.02683C5.56352 0.613496 6.02 0.346829 6.51735 0.333496H7.47799C7.98215 0.333496 8.43863 0.613496 8.69071 1.02683C8.81334 1.22683 8.8951 1.4735 8.87466 1.7335C8.86104 1.9335 8.92235 2.1335 9.03136 2.32016C9.37883 2.88683 10.1487 3.10016 10.7483 2.78016C11.4227 2.3935 12.2744 2.62683 12.6627 3.28683L13.1192 4.0735C13.5143 4.7335 13.2963 5.58016 12.615 5.96016C12.0359 6.30016 11.8315 7.0535 12.179 7.62683C12.288 7.80683 12.4106 7.96016 12.6014 8.0535ZM5.07298 7.00683C5.07298 8.0535 5.93824 8.88683 7.00789 8.88683C8.07754 8.88683 8.92235 8.0535 8.92235 7.00683C8.92235 5.96016 8.07754 5.12016 7.00789 5.12016C5.93824 5.12016 5.07298 5.96016 5.07298 7.00683Z"
      fill="white"
    />
  </svg>
</template>
