import weightIcon from "../../assets/icons/testTypes/scale.svg";
import waistCircIcon from "../../assets/icons/testTypes/womens belt.svg";
import bmiIcon from "../../assets/icons/testTypes/industrial scales.svg";
import stepsIcon from "../../assets/icons/testTypes/footprints.svg";


export const goalOptions = [
    {
        id: 'weight',
        name: 'Weight',
        unitOptions: ['KG (kilogram)', 'LBS (pounds)'],
        image: weightIcon,
    },
    {
        id: 'waist',
        name: 'Waist Circumference',
        unitOptions: ['CM (centimeter)', 'IN (inches)'],
        image: waistCircIcon,
    },
    {
        id: 'bmi',
        name: 'BMI',
        unit: '',
        image: bmiIcon,
    },
    {
        id: 'steps',
        name: 'Steps',
        unit: 'steps/day',
        image: stepsIcon,
    },
];

export const parameterMap = {
    'Weight': 'weight',
    'Waist Circumference': 'waist circumference',
    'BMI': 'bmi',
    'Steps': 'steps',
};

