<template>
  <main
    class="w-full mx-auto px-2 lg:px-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="w-full xl:mx-10 rounded-lg shadow-xl bg-white p-4 md:p-10">
      <div
        class="w-full flex flex-col items-start gap-4 md:flex-row xl:gap-6 mb-8"
      >
        <div
          class="w-full md:w-[40%] cursor-pointer"
        >
          <PatientProgressReport />
        </div>

        <div class="w-full md:w-[60%] h-[60%]">
          <PatientVitals :glucoseData="glucoseData" />
        </div>
      </div>

      <div>
        <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-5 mb-4">
          <PatientDocuments
            :isLoading="isGlucoseLoading"
            :isPending="isProfileLoading || isProgressScoreLoading"
          />
          <div>
            <ConnectedDevices />
          </div>

          <div>
            <BookAppointment />
          </div>

          <div>
            <RPM />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, inject } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { mapActions } from "@/hooks/mapStore";
import PatientProgressReport from "@/components/main/patient/dashboard/PatientProgressReport.vue";
import PatientVitals from "@/components/main/patient/dashboard/PatientVitals.vue";
import PatientDocuments from "@/components/main/patient/dashboard/PatientDocuments.vue";
import RPM from "@/components/main/patient/dashboard/RPM.vue";
import ConnectedDevices from "@/components/main/patient/dashboard/ConnectedDevices.vue";
import BookAppointment from "@/components/main/patient/dashboard/BookAppointment.vue";

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/fetchPatientProgressScore": fetchPatientProgressScore,
} = mapActions();

const currentTestType = ref("glucose");


// Fetching the blood glucose records with dynamic query parameters
const {
  data: glucoseData,
  isLoading: isGlucoseLoading,
  isError: isGlucoseError,
} = useQuery({
  queryKey: ["bloodGlucoseRecords", currentTestType],
  queryFn: () => fetchBloodGlucoseRecords({ test_type: currentTestType.value }),
  onSuccess: (data) => {},
  onError: (error) => {
    push.error("Error fetching blood glucose records.");
  },
});

// Fetching the patient profile
const {
  data: profileData,
  isLoading: isProfileLoading,
  isError: isProfileError,
} = useQuery({
  queryKey: ["patientProfile"],
  queryFn: fetchPatientProfile,
  onSuccess: (data) => {},
  onError: (error) => {
    push.error("Error fetching patient profile.");
  },
});

// Fetching the patient progress score
const {
  data: progressScoreData,
  isLoading: isProgressScoreLoading,
  isError: isProgressScoreError,
} = useQuery({
  queryKey: ["patientProgressScore"],
  queryFn: fetchPatientProgressScore,
  onSuccess: (data) => {},
  onError: (error) => {
    push.error("Error fetching patient progress score.");
  },
});
</script>

<style scoped>
.progress-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.progress-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 100px, 100px, 50px);
}

.progress-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 50px, 100px, 0);
  background: conic-gradient(#3498db 0% 80%, #ecf0f1 80% 100%);
}

.percentage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #3498db;
}
</style>
