<template>
  <div>
    <transition name="fade">
      <!-- When not collapsed, show the full profile status -->
      <div
        v-if="!isCollapsed"
        :class="[
          profileStatusClasses.bgClass,
          profileStatusClasses.textClass,
          profileStatusClasses.borderClass,
        ]"
        class="relative flex items-center justify-center text-center font-light py-3 w-full rounded-md"
      >
        <!-- Collapse (close) button -->
        <button
          @click="toggleCollapse"
          class="absolute top-2 right-2 text-xl text-black focus:outline-none"
          aria-label="Collapse Profile Status"
        >
          &times;
        </button>
        <p class="w-[92%]">
          Your profile is
          <span :class="profileStatusClasses.textClass" class="font-bold">
            {{ profilePercentage }}%
          </span>
          complete.
          <router-link
            to="/patient/settings/account-information"
            :class="profileStatusClasses.linkClass"
          >
            Complete your account setup and explore Turbomedic&apos;s features
          </router-link>
        </p>
      </div>
      <!-- When collapsed, show a minimal bar with an expand button -->
      <div
        v-else
        :class="[
          profileStatusClasses.bgClass,
          profileStatusClasses.textClass,
          profileStatusClasses.borderClass,
        ]"
        class="flex items-center justify-center p-3 w-fit rounded-md cursor-pointer"
      >
        <button
          @click="toggleCollapse"
          class="text-base font-medium text-Cinder focus:outline-none"
          aria-label="Expand Profile Status"
        >
         Profile Status
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";

const { "user/getProfilePercentage": profilePercentage } = mapGetters();

const isCollapsed = ref(false);

const toggleCollapse = () => {
  isCollapsed.value = !isCollapsed.value;
};

const profileStatusClasses = computed(() => {
  if (profilePercentage.value <= 65) {
    return {
      bgClass: "bg-red-500/90",
      textClass: "text-white",
      borderClass: "border-red-700",
      linkClass: "border-b-[0.1px] border-white",
    };
  }
  return {
    bgClass: "bg-BlueChalk",
    textClass: "text-ResolutionBlue",
    borderClass: "border-ResolutionBlue",
    linkClass: "border-b-[0.1px] border-ResolutionBlue",
  };
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
