<template>
  <header
    class="sticky top-0 z-50 pb-2 pt-6 lg:pb-3 lg:pt-7 px-2 lg:px-16 bg-WhiteLilac space-y-6"
  >
    <ProfileStatus v-if="!isAdmin && profilePercentage !== null" />

    <div class="flex flex-row items-center justify-between flex-wrap gap-2">
      <Hamburger />

      <div
        class="hidden lg:block px-3 py-2 md:px-6 md:py-3.5 bg-BlueChalk text-ResolutionBlue font-bold rounded-md text-base md:text-xl"
      >
        <span>{{ routeName }}</span>
      </div>

      <div class="flex items-center space-x-10 xl:space-x-16">
        <div class="flex items-center justify-center space-x-2">
          <div class="flex flex-row items-center space-x-4 md:space-x-8">
            <div v-if="!isAdmin">
              <p class="text-2xl font-bold">
                {{ greeting }}, {{ user?.first_name }}
              </p>
            </div>
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <SubHeader />

      <div class="flex items-center space-x-3">
        <router-link
          v-if="isAdmin"
          to="/admin/blog-posts"
          class="w-fit border border-ResolutionBlue py-2 px-5 rounded-[40px] text-ResolutionBlue text-center text-sm font-bold cursor-pointer"
        >
          Blog Posts
        </router-link>
        <div
          class="w-fit border border-ResolutionBlue py-2 px-5 rounded-[40px] text-ResolutionBlue text-center text-sm font-bold cursor-pointer"
          @click="openTakeTestModal"
        >
          New Tests
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, computed, inject } from "vue";
import { useRoute } from "vue-router";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { useQuery } from "@tanstack/vue-query";
import ProfileStatus from "./ProfileStatus.vue";
import Hamburger from "./Hamburger.vue";
// import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import SubHeader from "./SubHeader.vue";
import ProfileDropdown from "./ProfileDropdown.vue";

    const route = useRoute();
    const openTakeTestModal = inject("openTakeTestModal");
    const greeting = ref("");

    const { "user/fetchPatientProfile": fetchPatientProfile } = mapActions();

    const {
      "user/getUser": user,
      "auth/getIsAdmin": isAdmin,
      "user/getPatientProfile": getPatientProfile,
      "user/getProfilePercentage": profilePercentage,
    } = mapGetters();

    // Use Vue Query to fetch the patient profile data with the Vuex action
    const {
      data: patientProfileData,
      isLoading,
      isError,
    } = useQuery({
      queryKey: ["patientProfile"],
      queryFn: fetchPatientProfile,
    });

    // dynamic dashboard text display
    const routeName = computed(() => {
      const subHeaderName = route.meta.subHeaderName;
      if (typeof subHeaderName === "string") {
        return subHeaderName;
      } else {
        if (subHeaderName[0] === "Settings") {
          return subHeaderName[0];
        }
        return subHeaderName[subHeaderName.length - 1];
      }
    });

    const setGreeting = () => {
      const currentTime = new Date().getHours();
      if (currentTime >= 0 && currentTime < 12) {
        greeting.value = "Good morning";
      } else if (currentTime >= 12 && currentTime < 18) {
        greeting.value = "Good afternoon";
      } else {
        greeting.value = "Good evening";
      }
    };

    onMounted(async () => {
      setGreeting();
    });

</script>
