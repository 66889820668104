<template>
  <div
    class="w-full h-[350px] md:h-[370px] flex flex-col gap-5 bg-WhiteLilac md:p-7 rounded-xl"
  >
    <div class="flex items-center justify-between">
      <p class="font-semibold">Vitals</p>
      <!-- <img :src="expandIcon" alt="expand icon" /> -->
    </div>

    <div class="w-full h-full grid grid-cols-2 md:grid-cols-4 gap-5">
      <router-link
        v-for="(vital, index) in vitalsConfig"
        :key="index"
        :to="{
          path: '/patient/blood-glucose',
          query: { testType: vital.testType },
        }"
        class="h-full"
      >
        <div
          :class="`flex flex-col rounded-2xl gap-3 ${vital.bgColor} h-full px-5 py-3`"
        >
          <div class="flex items-center justify-between">
            <p class="text-xs text-OlsoGrey font-semibold">{{ vital.title }}</p>
            <img
              :src="vital.icon"
              class="p-2 bg-snowGray w-8 h-8 rounded-full"
              alt=""
            />
          </div>

          <div>
            <div class="text-lg md:text-2xl font-bold" :class="vital.textColor">
              {{ getVitalValue(vital.dataKey) || "---" }}
              <span class="text-xs font-semibold">{{ getVitalValue(vital.unitKey) }}</span>
            </div>
            <!-- <p class="font-normal text-[10px] text-DavyGrey mt-1">
              Up <span class="text-DarkMint">10%</span> from last week.
            </p> -->
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { mapGetters } from "@/hooks/mapStore";
import { testTypes } from "@/utils/mockData/testTypes";
import glucoseIcon from "@/assets/icons/glucose-icon.svg";
import heartRateIcon from "@/assets/icons/hearth-rate-icon.svg";
import bloodPresureIcon from "@/assets/icons/blood-pressure-icon.svg";
import weightIcon from "@/assets/icons/testTypes/scale.svg";
import spo2Icon from "@/assets/icons/testTypes/medical device for measuring blood saturation.svg";
import tempertatureIcon from "@/assets/icons/testTypes/glass-medical-thermometer.svg";
import ecgIcon from "@/assets/icons/testTypes/pulse.svg";
import lungCapacityIcon from "@/assets/icons/lung-capacity-icon.svg";


const props = defineProps({
  glucoseData: Object, // The data fetched via useQuery in PatientDashboardView.vue
});

const {
  "user/getUserBloodGlucoseRecords": getUserBloodGlucoseRecords,
  "user/getPatientHealthScore": getPatientHealthScore,
} = mapGetters();

const vitalsConfig = computed(() => [
  {
    title: "Glucose",
    icon: glucoseIcon,
    testType: "glucose",
    dataKey: "Glucose",
    unitKey: "Glucose_Unit",
    bgColor: "bg-irisWhite",
    textColor: "text-DarkJungle",
  },
  {
    title: "Blood Pressure",
    icon: bloodPresureIcon,
    testType: "blood_pressure",
    dataKey: "Blood_Pressure",
    unitKey: "Blood_Pressure_Unit",
    bgColor: "bg-PattensBlue",
    textColor: "text-DarkJungle",
  },
  {
    title: "Weight",
    icon: weightIcon,
    testType: "weight",
    dataKey: "Weight (BMI)",
    unitKey: "Weight_Unit",
    bgColor: "bg-ScotchMist",
    textColor: "text-DarkJungle",
  },
  {
    title: "Spo2",
    icon: spo2Icon,
    testType: "heart_rate",
    dataKey: "SpO2",
    unitKey: "SpO2_Unit",
    bgColor: "bg-peach",
    textColor: "text-DarkJungle",
  },
  {
    title: "Temperature",
    icon: tempertatureIcon,
    testType: "temperature",
    dataKey: "Temperature",
    unitKey: "Temperature_Unit",
    bgColor: "bg-leafGreen",
    textColor: "text-DarkJungle",
  },
  {
    title: "ECG",
    icon: ecgIcon,
    testType: "ecg",
    dataKey: "ECG (Heart Rate)",
    unitKey: "ECG_Unit",
    bgColor: "bg-blandBlue",
    textColor: "text-DarkJungle",
  },

  // {
  //   title: "Lung Capacity",
  //   icon: lungCapacityIcon,
  //   testType: "ecg",
  //   dataKey: "average_ecg",
  //   unit: "L",
  //   bgColor: "bg-fadedBlue",
  //   textColor: "text-DarkJungle",
  // },
]);

const getVitalValue = (dataKey) => {
  if (!getPatientHealthScore.value?.data) return null; // Check for nested data
  
  // Handle Blood Pressure
  if (dataKey === "Blood_Pressure") {
    const systolic = getPatientHealthScore.value.data["Blood Pressure (Systolic)"];
    const diastolic = getPatientHealthScore.value.data["Blood Pressure (Diastolic)"];
    return systolic && diastolic ? `${systolic}/${diastolic}` : "---";
  }
  
  // Access other values from nested data
  return getPatientHealthScore.value.data[dataKey] || null;
};


</script>
