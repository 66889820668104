import axios from "@/api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getPatientDiabetesRiskPrediction() {
    const res = await axios.post("/data/patient/predict/diabetes");

    return res.data;
  },

  async getPatientCardiovascularRiskPrediction() {
    const res = await axios.post("/data/patient/predict/cardio");

    return res.data;
  },

  async getPatientSIUnit() {
    const res = await axios.post("/data/patient/convert/si");

    return res.data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
