<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="w-full">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-[repeat(4,_minmax(0px,_280px))] grid-rows-[repeat(2,_minmax(0px,_280px))] gap-4 xl:gap-y-4 xl:gap-x-6"
        >
          <SettingsBox
            v-for="(item, index) in paths"
            :key="index"
            :path="item.path"
            :containerClass="item.containerClass"
            :iconContainerClass="item.iconContainerClass"
            :icon="item.icon"
            :hoverIcon="item.hoverIcon"
            :title="item.title"
            :description="item.description"
            :titleClass="item.titleClass"
            :descriptionClass="item.descriptionClass"
            :hoverImage="item.hoverImage"
            :hoverImageClasses="item.hoverImageClasses"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SettingsBox from "@/components/main/admin/settings/SettingsBox.vue";
import icon1 from "@/assets/icons/setting-user-icon.svg";
import hoverimage1 from "@/assets/images/account-information-hover.png";
import icon2 from "@/assets/icons/setting-device-icon.svg";
import hoverIcon2 from "@/assets/icons/setting-device-icon-2.svg";
import hoverimage2 from "@/assets/images/devices-hover.png";
import icon3 from "@/assets/icons/setting-report-and-statistic-icon.svg";
import hoverIcon3 from "@/assets/icons/setting-report-and-statistic-icon-2.svg";
import hoverimage3 from "@/assets/images/report-and-statistics-hover.png";
import icon4 from "@/assets/icons/security.svg";
import hoverIcon4 from "@/assets/icons/security-2.svg";
import hoverimage4 from "@/assets/images/security-and-preferences-hover.png";
import icon5 from "@/assets/icons/fi_link-icon.svg";
import hoverIcon5 from "@/assets/icons/fi_link-icon-2.svg";
import hoverimage5 from "@/assets/images/account-linking-hover.png";
import icon6 from "@/assets/icons/settings-notifications-icon.svg";
import hoverIcon6 from "@/assets/icons/notifications-hover.svg";
import hoverimage6 from "@/assets/images/notification-hover-image.png";

const paths = [
  {
    path: "/patient/settings/account-information",
    containerClass: "hover:bg-DodgerBlue",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "Account Information",
    description: "Manage your account Information.",
    titleClass: "group-hover:text-white",
    descriptionClass: "group-hover:text-white",
    hoverImage: hoverimage1,
    hoverImageClasses: "top-0 right-0",
  },
  {
    path: "/patient/settings/device-information",
    containerClass: "hover:bg-LemonGreen",
    iconContainerClass: "group-hover:bg-DarkMint",
    icon: icon2,
    hoverIcon: hoverIcon2,
    title: "Devices",
    description: "Check your devices here",
    titleClass: "",
    descriptionClass: "group-hover:text-DarkJungle",
    hoverImage: hoverimage2,
    hoverImageClasses: "bottom-0 right-0",
  },
  {
    path: "/patient/settings/report-and-statictics",
    containerClass: "hover:bg-NavajoWhite",
    iconContainerClass: "group-hover:bg-SaffronMango",
    icon: icon3,
    hoverIcon: hoverIcon3,
    title: "Diagnostic Imaging Hub",
    description: "Request a report over a period",
    titleClass: "",
    descriptionClass: "group-hover:text-DarkJungle",
    hoverImage: hoverimage3,
    hoverImageClasses: "top-3 right-0",
  },
  {
    path: "/patient/settings/security-and-preferences",
    containerClass: "hover:bg-LightGreen",
    iconContainerClass: "group-hover:bg-DarkMint",
    icon: icon4,
    hoverIcon: hoverIcon4,
    title: "Security and Preferences",
    description: "Manage your security and preferences",
    titleClass: "",
    descriptionClass: "group-hover:text-DarkJungle",
    hoverImage: hoverimage4,
    hoverImageClasses: "top-8 right-0",
  },
  {
    path: "/patient/settings/account-linking",
    containerClass: "hover:bg-PendingYellow",
    iconContainerClass: "group-hover:bg-LightOrange",
    icon: icon5,
    hoverIcon: hoverIcon5,
    title: "Account Linking",
    description: "Manage your linked patients effectively",
    titleClasses: "",
    descriptionClass: "",
    hoverImage: hoverimage5,
    hoverImageClasses: "top-5 right-0",
  },
  {
    path: "/patient/settings/notifications",
    containerClass: "hover:bg-lavenderBlue",
    iconContainerClass: "group-hover:bg-ResolutionBlue",
    icon: icon6,
    hoverIcon: hoverIcon6,
    title: "Notifications",
    description: "Manage your notification",
    titleClasses: "",
    descriptionClass: "",
    hoverImage: hoverimage6,
    hoverImageClasses: "top-5 right-0",
  },
];
</script>
