import axios from "@/api";
import { noDP } from "./user";

const state = {
  userSignUp: null,
  user_exist: null,
  userProfile: null,
  isLoggedIn: false,
  isAdmin: false,
};

const getters = {
  getIsAdmin: (state) => {
    return state.isAdmin;
  },
  getuserSignUp: (state) => {
    return state.userSignUp;
  },
  getUserExist: (state) => {
    return state.user_exist;
  },
  getUserProfile: (state) => {
    return state.userProfile;
  },
};

const mutations = {
  setUserSignUp(state, userSignUpData) {
    state.userSignUp = userSignUpData;
  },
  setUserExist(state, payload) {
    state.user_exist = payload;
  },
  setLoginStatus(state, payload) {
    state.isLoggedIn = payload;
  },
  setAdminStatus(state, payload) {
    state.isAdmin = payload;
  },
};

const actions = {
  async resendVerificationEmail(_, email) {
    const response = await axios.put(`/auth/resend-confirmation-email`, {
      email,
    });
    return response.data;
  },

  async verifyEmail(_, { email, token }) {
    const response = await axios.put(`/auth/confirmation-ready`, null, {
      params: { email, token },
    });
    return response.data;
  },

  async signup(_, signupCredentials) {
    const response = await axios.post("/auth/register", signupCredentials);
    // commit("setUser", response.data.user);
    // commit("setIsAuthenticated", true);
    return response.data;
  },

  async signupPatient(_, signupCredentials) {
    const response = await axios.post(
      "/auth/admin/register-user",
      signupCredentials
    );
    // commit("setUser", response.data.user);
    // commit("setIsAuthenticated", true);
    return response.data;
  },

  async isUserExist({ commit }, userEmail) {
    const response = await axios.get(`auth/email-exists?email=${userEmail}`);
    commit("setUserExist", response.data);
    return response.data;
  },

  async login({ commit }, payload) {
    const response = await axios.post("/auth/login", payload);
    const data = response.data;

    commit("user/setUser", data.user, { root: true });
    commit("user/setPatientId", data.patient_id, { root: true });
    commit("user/setProfilePercentage", data.user.percentage_complete, {
      root: true,
    });

    if (data.user.avatar_url) {
      commit("user/setProfilePicture", data.user.avatar_url, {
        root: true,
      });
    } else {
      commit("user/setProfilePicture", noDP, { root: true });
    }

    commit("setLoginStatus", data.login);

    if (data.user.roles.includes("admin")) {
      commit("setAdminStatus", true);
    }

    return response.data;
  },

  async isAuthenticated({ commit }) {
    try {
      const response = await axios.get("/auth/authenticated");
      const data = response.data;
      if (data?.authenticated) {
        commit("user/setUser", data.user, { root: true });
        commit("user/setPatientId", data.patient_id, { root: true });
        commit("user/setProfilePercentage", data.user.percentage_complete, {
          root: true,
        });
        if (data.user.avatar_url) {
          commit("user/setProfilePicture", data.user.avatar_url, {
            root: true,
          });
        } else {
          commit("user/setProfilePicture", noDP, { root: true });
        }
        commit("setLoginStatus", true);
        if (data.user.roles.includes("admin")) {
          commit("setAdminStatus", true);
        }
        return response.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  async logout() {
    const response = await axios.post("/auth/logout");
    return response.data;
  },

  async sendResetToken(_, payload) {
    await axios.post("/auth/reset-password", payload);
  },

  async createNewPassword(_, payload) {
    await axios.put(
      `/auth/create-new-password?email=${payload.email}&token=${payload.token}`,
      payload.data
    );
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
