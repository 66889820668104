<template>
  <ModalWrapper>
    <section
      class="max-w-xl p-10 w-full flex flex-col gap-6 bg-white relative rounded-3xl"
    >
      <div class="w-full flex items-center justify-between">
        <h2 class="text-xl font-bold text-GunMetal">Set a Goal</h2>

        <div class="cursor-pointer" @click="closeProgressGoalSelectModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <section class="w-full flex flex-col gap-5">
        <div class="w-full space-y-3 p-3 bg-WhiteLilac rounded-lg">
          <div
            v-for="goal in goalOptions"
            :key="goal.id"
            class="w-full flex items-center gap-3 p-3 text-OlsoGrey text-sm font-bold bg-white border cursor-pointer rounded-lg"
            :class="
              selectedGoal?.id === goal.id
                ? 'border-ResolutionBlue'
                : 'border-CloudGrey'
            "
            @click="selectedGoal = goal"
          >
            <div
              class="w-8 h-7 p-1 bg-white border border-CloudGrey rounded-sm"
            >
              <img :src="goal.image" :alt="goal.name" />
            </div>
            <p>{{ goal.name }}</p>
          </div>
        </div>

        <div class="w-fit flex items-center gap-4 self-center">
          <BaseButton
            type="button"
            :classes="'flex items-center justify-center py-3 px-6 bg-transparent border border-ResolutionBlue text-ResolutionBlue rounded-full'"
            @click="closeProgressGoalSelectModal"
          >
            Cancel
          </BaseButton>

          <BaseButton
            type="button"
            :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
            :disabled="!selectedGoal"
            @click="handleContinue"
          >
            Continue
          </BaseButton>
        </div>
      </section>
    </section>
  </ModalWrapper>
</template>

<script setup>
import { ref, inject } from "vue";
import { goalOptions } from "@/utils/mockData/goals";
import ModalWrapper from "../../testCenter/modals/ModalWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";

const emit = defineEmits(["close", "continue"]);
const selectedGoal = ref(null);

const closeProgressGoalSelectModal = inject("closeProgressGoalSelectModal");
const openSetGoalModal = inject("openSetGoalModal");

const handleContinue = () => {
  if (selectedGoal.value) {
    openSetGoalModal(selectedGoal.value);
    closeProgressGoalSelectModal();
  }
};
</script>
