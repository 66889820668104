<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="w-full">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-[repeat(4,_minmax(0px,_280px))] grid-rows-[repeat(2,_minmax(0px,_280px))] gap-4 xl:gap-y-4 xl:gap-x-6"
        >
          <AIRoutes
            v-for="(item, index) in paths"
            :key="index"
            :path="item.path"
            :containerClass="item.containerClass"
            :iconContainerClass="item.iconContainerClass"
            :icon="item.icon"
            :hoverIcon="item.hoverIcon"
            :title="item.title"
            :description="item.description"
            :titleClass="item.titleClass"
            :descriptionClass="item.descriptionClass"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import AIRoutes from "@/components/main/patient/ai/AIRoutes.vue";
import icon1 from "@/assets/icons/setting-user-icon.svg";

const paths = [
  {
    path: "",
    // path: "/patient/ai/ask-turbomed-ai",
    containerClass:
      "hover:bg-[linear-gradient(to_top_right,#FFFFFF_50%,#F3C7DA_90%)]",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "Ask Dr Deuce",
    description: "Chat with our Ai to get update about your health",
    titleClass: "group-hover:text-Mirage",
    descriptionClass: "group-hover:text-OlsoGrey",
  },
  {
    path: "/patient/ai/diabetes-risk-prediction",
    containerClass:
      "hover:bg-[linear-gradient(to_bottom_left,#FFFFFF_50%,#CBC5E1_90%)]",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "Diabetes Risk Assessment",
    description: "Evaluates the likelihood of developing diabetes",
    titleClass: "group-hover:text-Mirage",
    descriptionClass: "group-hover:text-OlsoGrey",
  },
  {
    path: "/patient/ai/cardiovascular-risk-prediction-calculator",
    containerClass:
      "hover:bg-[linear-gradient(to_top_left,#FFFFFF_50%,#E9EDD1_90%)]",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "Cardiovascular Risk Calculator",
    description: "Estimates the risk of heart disease",
    titleClass: "group-hover:text-Mirage",
    descriptionClass: "group-hover:text-OlsoGrey",
  },
  {
    path: "/patient/ai/si-unit-converter",
    containerClass:
      "hover:bg-[linear-gradient(to_top_left,#FFFFFF_50%,#E9EDD1_90%)]",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "SI Unit Converter",
    description: "Get your converted SI unit here",
    titleClass: "group-hover:text-Mirage",
    descriptionClass: "group-hover:text-OlsoGrey",
  },
  {
    path: "",
    // path: "/patient/ai/weight-calculator",
    containerClass:
      "hover:bg-[linear-gradient(to_bottom_right,#FFFFFF_50%,#CFD9EE_90%)]",
    iconContainerClass: "group-hover:bg-white",
    icon: icon1,
    hoverIcon: null,
    title: "Weight Calculator",
    description: "Calculator your weight here",
    titleClass: "group-hover:text-Mirage",
    descriptionClass: "group-hover:text-OlsoGrey",
  },
];
</script>
