<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.0625 6.7698H13.9451"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.962 9.37331H10.9681"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00298 9.37331H8.00915"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.03813 9.37331H5.04431"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.962 11.9641H10.9681"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00298 11.9641H8.00915"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.03813 11.9641H5.04431"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6955 1.8335V4.02735"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.31075 1.8335V4.02735"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.8255 2.88623H5.18064C3.22285 2.88623 2 3.97685 2 5.98158V12.0147C2 14.0509 3.22285 15.1668 5.18064 15.1668H10.8193C12.7833 15.1668 14 14.0698 14 12.0651V5.98158C14.0062 3.97685 12.7895 2.88623 10.8255 2.88623Z"
      stroke="#FFFFFF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
