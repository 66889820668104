<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="bg-white xl:mx-10 rounded-lg shadow-xl w-full p-4 md:p-10 h-[40rem] relative"
    >
      <div
        class="w-full md:w-[80%] flex flex-row items-center justify-between mb-5 h-[10%]"
      >
        <div
          class="flex items-center flex-wrap gap-y-3 gap-x-10 md:gap-x-16 py-4 md:pt-0"
        >
          <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">
            Account Information
          </p>

          <div
            class="flex flex-row items-center rounded-sm bg-WhiteLilac p-1.5"
          >
            <span
              @click="toggleTabs('personal')"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-6 md:px-10 py-1.5': openTab !== 'personal',
                'font-bold rounded-sm px-6 md:px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 'personal',
              }"
            >
              Personal</span
            >
            <span
              @click="toggleTabs('medical')"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-6 md:px-10 py-1.5': openTab !== 'medical',
                'font-bold rounded-sm px-6 md:px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 'medical',
              }"
            >
              Medical</span
            >
            <span
              @click="toggleTabs('advance-metrics')"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-6 md:px-10 py-1.5':
                  openTab !== 'advance-metrics',
                'font-bold rounded-sm px-6 md:px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 'advance-metrics',
              }"
            >
              Advance metrics</span
            >
          </div>
        </div>
      </div>

      <div class="h-[90%] no-scrollbar">
        <div class="overflow-x-auto h-full py-6 no-scrollbar">
          <div
            class="w-[80%] flex flex-col items-start md:flex-row md:gap-x-8 no-scrollbar"
          >
            <ProfilePicture />

            <PersonalInformation v-show="openTab === 'personal'" />
            <MedicalInformation v-show="openTab === 'medical'" />
            <AdvanceMetrics v-show="openTab === 'advance-metrics'" />
          </div>

          <div class="flex justify-center mt-8">
            <p
              class="text-center text-xs md:text-sm font-light text-BluishGrey md:max-w-sm"
            >
              If you have any issues with your information, please send a
              message to hello@turbomedics.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import MedicalInformation from "@/components/main/patient/accountInformation/MedicalInformation.vue";
import PersonalInformation from "@/components/main/patient/accountInformation/PersonalInformation.vue";
import AdvanceMetrics from "@/components/main/patient/accountInformation/AdvanceMetrics.vue";
import ProfilePicture from "@/components/main/patient/accountInformation/ProfilePicture.vue";

const route = useRoute();
const router = useRouter();
const openTab = ref("personal");

const toggleTabs = (tabName) => {
  openTab.value = tabName;
  // Update URL with tab query parameter
  router.push({
    query: {
      ...route.query, // Preserve existing query params
      tab: tabName,
    },
  });
};

// Initialize tab from URL query parameter
onMounted(() => {
  if (route.query.tab) {
    openTab.value = route.query.tab;
  }
});
</script>
