<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
    >
      <div class="w-full border px-2 md:px-5 py-6 rounded-xl">
        <div class="flex justify-between items-center gap-3 mb-3">
          <p class="font-urbanist-regular font-bold">My Appointments</p>

          <BaseButton
            v-if="appointments?.length > 0"
            class="bg-ResolutionBlue py-1.5 px-10 md:py-2 md:px-12 rounded-[40px] text-white text-xs md:text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            @click="openAppointmentModal"
          >
            Book Now
          </BaseButton>
        </div>

        <!-- Always show the tabs -->
        <Tab :status="currentStatus" @changeStatus="changeStatus" />

        <!-- Loading state -->
        <div v-if="isLoading" class="w-6 h-6 mx-auto flex justify-center items-center">
          <SpinnerIcon />
        </div>

        <!-- Error state -->
        <ErrorMessage
          v-else-if="isError"
          message="An error occurred while fetching appointments."
        />

        <!-- Empty state -->
        <div
          v-else-if="appointments?.length === 0"
          class="text-center py-4 text-DavyGrey"
        >
          No {{ currentStatus }} appointments available.
        </div>

        <!-- Appointments Table -->
        <div v-else>

          <Table :columns="columns" :tableData="appointments" class="w-full" />

          <!-- Pagination -->
          <Pagination
            :current-page="currentPage"
            :number-of-pages="totalPagesComputed"
            :is-pending="isLoading"
            :fetching-next-page="isLoading"
            @prevPage="currentPage--"
            @nextPage="currentPage++"
            @goToPage="(page) => (currentPage = page)"
          />
        </div>
      </div>

      <CancelAppointmentModal
        v-if="showCancelModal"
        :appointment="selectedAppointment"
        @close="closeCancelModal"
        @cancel-appointment="handleCancelAppointment"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, inject, watchEffect, h } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { mapActions, mapGetters } from "vuex";
import { push } from "notivue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import Tab from "@/components/main/patient/appointment/Tab.vue";
import AppointmentActionBox from "@/components/main/patient/appointment/AppointmentActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";
import CancelAppointmentModal from "@/components/main/patient/appointment/CancelAppointmentModal.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import loadingIcon from "@/assets/icons/loading-01.svg";
import Pagination from "@/components/main/ui/Pagination.vue";
import SpinnerIcon from "@/components/icons/SpinnerIcon.vue";
import NoDataMessage from "@/components/main/ui/NoDataMessage.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import Table from "@/components/main/ui/table/Table.vue";

const route = useRoute();
const store = useStore();

// Refs
const currentPage = ref(1);
const appointmentsPerPage = ref(10);
const currentStatus = ref(route.query.status || "all");
const appointments = ref([]);

// Modal-related refs
const showCancelModal = ref(false);
const selectedAppointment = ref(null);

// mapActions to dispatch actions from Vuex
const { fetchPatientAppointments, cancelAppointment } = mapActions(
  "appointment",
  ["fetchPatientAppointments", "cancelAppointment"]
);

// mapGetters to retrieve state from Vuex
const { getAppointmentsByStatus, totalPages } = mapGetters("appointment", [
  "getAppointmentsByStatus",
  "totalPages",
]);

const totalPagesComputed = computed(
  () => store.getters["appointment/totalPages"]
);

const openAppointmentModal = inject("openAppointmentModal");
const setAppointmentModalVal = inject("setAppointmentModalVal");

// Fetch appointments using Vuex actions
const fetchAppointments = async ({ status, page, perPage }) => {
  try {
    const appointmentsData = await store.dispatch(
      "appointment/fetchPatientAppointments",
      {
        status,
        page,
        perPage,
      }
    );
    return appointmentsData;
  } catch (error) {
    push.error("Error fetching appointments:", error);
  }
};

// Use Vue Query to manage fetching appointments
const { data, isLoading, isError } = useQuery({
  queryKey: ["appointments", currentStatus, currentPage],
  queryFn: () =>
    fetchAppointments({
      status: currentStatus.value,
      page: currentPage.value,
      perPage: appointmentsPerPage.value,
    }),
});

// Columns for the Table component
const columns = ref([
   {
    title: "Scheduled Date",
    dataIndex: "date",
    render: (record) => h("span", null, record.date || "N/A"),
  },
  {
    title: "Health Center",
    dataIndex: "health_centre.name",
    render: (record) => h("span", null, record.health_centre?.name || "N/A"),
  },
  // {
  //   title: "City",
  //   dataIndex: "city_of_centre",
  //   render: (record) => h("span", null, record.city_of_centre || "N/A"),
  // },
  {
    title: "Address",
    dataIndex: "health_centre.address",
    render: (record) => h("span", null, record.health_centre?.address || "N/A"),
  },
  {
    title: "HC Phone",
    dataIndex: "health_centre.phone",
    render: (record) => h("span", null, record.health_centre?.phone || "N/A"),
  },
  {
    title: "Appointment Type",
    dataIndex: "type",
    render: (record) => h("span", null, record.type || "N/A"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (record) => {
      const statusClasses = {
        Scheduled: "text-BrightBlue",
        Pending: "text-OrangePeel",
        Completed: "text-DarkMint",
        Cancelled: "text-DeepCarminePink",
        Expired: "text-DeepCarminePink",
      };
      return h(
        "span",
        { class: statusClasses[record.status] || "text-DavyGrey" },
        record.status
      );
    },
  },
  {
    title: "Start Time",
    dataIndex: "time",
    render: (record) => h("span", null, record.time || "N/A"),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render: (record) => {
      if (
        record.status !== "Cancelled" &&
        record.status !== "Expired" &&
        record.status !== "Completed"
      ) {
        return h("div", { class: "relative" }, [
          h(
            "button",
            {
              class:
                "hover:bg-WhiteLilac p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300",
            },
            h("img", {
              src: optionButton,
              alt: "three dots",
              onClick: () => toggleMenu(record.id), // Function to toggle visibility of the menu
            })
          ),
          h(AppointmentActionBox, {
            class: "absolute bg-white shadow-lg p-4 rounded",
            style: { display: record.showMenu ? "block" : "none" },
            onEdit: () => setAppointmentModal(record),
            onCancel: () => cancelCurrentAppointment(record),
          }),
        ]);
      }
      return null;
    },
  },
]);

// Function to toggle the menu visibility
const toggleMenu = (id) => {
  appointments.value = appointments.value.map((appointment) =>
    appointment.id === id
      ? { ...appointment, showMenu: !appointment.showMenu }
      : { ...appointment, showMenu: false }
  );
};

watchEffect(() => {
  if (data.value) {
    appointments.value = data.value; // Update the appointments ref with data from Vue Query
  }
});

const changeStatus = async (status) => {
  currentStatus.value = status;
  currentPage.value = 1;
};

const handleCancelAppointment = async (appointmentId) => {
  // Close the cancel modal
  closeCancelModal();
  // Refresh the appointment list
};

const setAppointmentModal = (appointment) => {
  setAppointmentModalVal({
    type: "edit",
    country: appointment?.health_centre?.country || "",
    state: appointment?.state_of_centre || "",
    city: appointment?.city_of_centre || "",
    center: appointment?.health_centre?.name || "",
    appointmentReason: appointment?.reason_for_appointment || "",
    address: appointment?.health_centre?.address || "",
    date: appointment?.date || "",
    time: appointment?.time || "",
    appointmentId: appointment?.id || "",
  });

  openAppointmentModal(); // Open the modal after setting values
};

const cancelCurrentAppointment = (appointment) => {
  selectedAppointment.value = appointment;
  showCancelModal.value = true;
};

const closeCancelModal = () => {
  showCancelModal.value = false;
  selectedAppointment.value = null;
};
</script>
