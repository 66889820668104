import { io } from "socket.io-client";
import appointment from "./appointment";

const state = {
  glucoseData: null,
  callData: null,
};

const mutations = {
  setGlucoseData(state, data) {
    state.glucoseData = data;
  },
  setCallData(state, data) {
    state.callData = data;
  },
};

const getters = {
  glucoseData: (state) => state.glucoseData,
  callData: (state) => state.callData,
};

let socket;

export const initializeSocket = (userId, store) => {
  const socketUrl = "wss://dev.turbomedics.com/?EIO=4&transport=websocket";
  socket = io(socketUrl);

  // When connected, join the room
  socket.on("connect", () => {
    if (socket.connected) {
      socket.emit("join", { room: userId });

      // Listen for glucose data and commit it to the Vuex store
      socket.on("join", (data) => {});

      // Listen for glucose data and commit it to the Vuex store
      socket.on("new_glucose_data", (data) => {
        store.commit("socket/setGlucoseData", data);
      });

      // Listen for notifications and commit them to the notifications.js module using rootState
      socket.on("notification", (notification) => {
        // Use rootState to commit to notifications.js
        store.commit("notifications/addNotification", notification, {
          root: true,
        });
      });

      socket.on("appointment", (data) => {
        // store.commit("appointment/setAppointments", data, { root: true });
      });

      socket.on("make_call", (payload) => {
        store.commit("socket/setCallData", payload);
      });
    }
  });

  // Handle disconnection
  socket.on("disconnect", () => {
    console.error("Socket disconnected");
  });

  // Handle connection error
  socket.on("connect_error", (err) => {
    console.error("Socket connection error:", err);
  });

  return socket;
};

export const getSocket = () => socket;

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
