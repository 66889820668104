<template>
  <ModalWrapper>
    <section
      class="max-w-3xl w-full p-10 flex flex-col gap-8 bg-white relative rounded-3xl"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center gap-1">
          <h2 class="text-xl font-bold text-GunMetal">Input your Height</h2>
          <div class="relative group">
            <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
            <div
              class="absolute left-[110%] top-1/2 transform -translate-y-1/2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[500px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-2 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900 z-40"
            >
              You need to input your height before you can proceed to setting a
              goal.
            </div>
          </div>
        </div>
        <div class="cursor-pointer" @click="closeModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div
        class="w-full flex items-center justify-between px-1.5 py-2.5 border-b border-BlueChalk"
      >
        <div class="flex flex-col gap-1">
          <label class="text-xs text-BluishGrey">Input your Height</label>
          <input
            type="number"
            v-model="height"
            class="w-full outline-none text-DarkJungle text-sm font-bold"
            placeholder="Enter your height"
          />
        </div>

        <!-- Dynamic Tabs Component -->
        <div
          class="w-fit flex items-center px-1.5 py-1 bg-BlueChalk rounded-full text-xs font-normal"
        >
          <div
            v-for="(unit, index) in units"
            :key="index"
            :class="{
              'bg-ResolutionBlue text-white': isActiveUnit(unit),
              'text-Gravel': !isActiveUnit(unit),
            }"
            @click="setUnit(unit)"
            class="cursor-pointer rounded-full py-1 px-3 transition-all duration-200"
          >
            {{ unit }}
          </div>
        </div>
      </div>

      <div class="w-fit flex items-center gap-4 self-center">
        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-transparent border border-ResolutionBlue text-ResolutionBlue rounded-full'"
          @click="closeModal"
        >
          Cancel
        </BaseButton>

        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
          :disabled="!height || !selectedUnit"
          @click="handleContinue"
        >
          {{ isUpdating ? "..." : "Set a Goal" }}
        </BaseButton>
      </div>
    </section>
  </ModalWrapper>
</template>

<script setup>
import { ref, inject, watch, onMounted } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { push } from "notivue";
import ModalWrapper from "../../testCenter/modals/ModalWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import InfoIcon from "@/assets/icons/info_outline.svg";

const props = defineProps(["goalId"]);

const { "user/updateUserHeight": updateUserHeight } = mapActions();

const height = ref("");
const selectedUnit = ref("ft");

const units = ["ft", "cm"];

const emit = defineEmits(["height-submitted"]);

const closeModal = () => {
  closeInputHeightModal();
};

// Function to check if the selected unit is the active one
const isActiveUnit = (unit) => selectedUnit.value === unit;

// Function to set the selected unit
const setUnit = (unit) => {
  selectedUnit.value = unit;
};

// Use mutation hook for the height update
const { mutate: updateHeight, isLoading: isUpdating } = useMutation({
  mutationFn: async () => {
    const payload = {
      height: parseFloat(height.value),
      height_units: selectedUnit.value,
    };

    return await updateUserHeight(payload);
  },
  onSuccess: () => {
    push.success("Height updated successufully");

    const goalId = props.goalId;
    if (goalId) {
      openGoalSummaryModal(goalId);
    }

    closeInputHeightModal();
  },
  onError: (error) => {
    push.error(error.response?.data?.message || "Error updating height");
  },
});

const handleContinue = () => {
  if (height.value && selectedUnit.value) {
    updateHeight(); // Trigger the mutation
  } else {
    push.error("Please enter a valid height and select a unit.");
  }
};

const closeInputHeightModal = inject("closeInputHeightModal");
const openGoalSummaryModal = inject("openGoalSummaryModal");

</script>
