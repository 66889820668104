<template>
  <ModalWrapper>
    <section
      class="max-w-5xl w-full p-10 flex flex-col gap-8 bg-white relative rounded-3xl"
      v-if="selectedGoal"
    >
      <div class="w-full flex items-center justify-between">
        <div class="flex items-center gap-1">
          <h2 class="text-xl font-bold text-GunMetal">
            Set A {{ selectedGoal?.name }} Goal
          </h2>
          <div class="relative group">
            <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
            <div
              class="absolute left-[110%] top-1/2 transform -translate-y-1/2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[500px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-2 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
            >
              Input your current and target
              {{ selectedGoal?.name?.toLowerCase() }}
              to help us personalize your goals
            </div>
          </div>
        </div>
        <div class="cursor-pointer" @click="closeSetGoalModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 after:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div class="w-full grid grid-cols-2 gap-6">
        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >What are you trying to achieve</label
          >
          <CustomDropdown
            v-model="selectedAchievement"
            :options="achievementOptions"
          />
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey">Start Date</label>
          <div
            class="w-full cursor-pointer relative"
            @click.stop="startDatePickerOpen = !startDatePickerOpen"
            @blur="startDatePickerOpen = false"
          >
            <input
              type="date"
              ref="startDatePicker"
              v-model="startDate"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              :class="{ 'ring-2 ring-BlueGem': startDatePickerOpen }"
            />
            <div
              class="w-full pb-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span class="text-DarkJungle text-sm font-bold">{{
                formattedStartDate || "Choose date"
              }}</span>
              <img
                :src="calendarGrayIcon"
                alt="calendar icon"
                class="ml-2 w-5 h-5"
              />
            </div>
          </div>
        </div>

        <div v-if="selectedGoal?.name !== 'BMI'" class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >Select a Unit for {{ selectedGoal?.name }}</label
          >
          <CustomDropdown v-model="selectedUnit" :options="unitOptions" />
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey">Start Time</label>
          <div
            class="w-full cursor-pointer relative"
            @click.stop="startTimePickerOpen = !startTimePickerOpen"
            @blur="startTimePickerOpen = false"
          >
            <input
              type="time"
              ref="startTimePicker"
              v-model="startTime"
              :min="minStartDate"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              :class="{ 'ring-2 ring-BlueGem': startTimePickerOpen }"
            />
            <div
              class="w-full pb-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span class="text-DarkJungle text-sm font-bold">{{
                formattedStartTime || "Choose time"
              }}</span>
              <img :src="clockIcon" alt="clock icon" class="ml-2 w-5 h-5" />
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >Current {{ selectedGoal?.name }}</label
          >
          <input
            type="number"
            v-model="currentValue"
            class="w-full pb-2.5 border-b border-BlueChalk outline-none"
            placeholder="Enter the current value"
          />
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey">End Date</label>
          <div
            class="w-full cursor-pointer relative"
            @click.stop="endDatePickerOpen = !endDatePickerOpen"
            @blur="endDatePickerOpen = false"
          >
            <input
              type="date"
              ref="endDatePicker"
              v-model="endDate"
              :min="minEndDate"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              :class="{ 'ring-2 ring-BlueGem': endDatePickerOpen }"
            />
            <div
              class="w-full pb-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span class="text-DarkJungle text-sm font-bold">{{
                formattedEndDate || "Choose date"
              }}</span>
              <img
                :src="calendarGrayIcon"
                alt="calendar icon"
                class="ml-2 w-5 h-5"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >Target {{ selectedGoal?.name }}</label
          >
          <input
            type="text"
            v-model="targetValue"
            class="w-full pb-2.5 border-b border-BlueChalk outline-none"
            placeholder="Target value"
          />
        </div>

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey">End Time</label>
          <div
            class="w-full cursor-pointer relative"
            @click.stop="endTimePickerOpen = !endTimePickerOpen"
            @blur="endTimePickerOpen = false"
          >
            <input
              type="time"
              ref="endTimePicker"
              v-model="endTime"
              class="custom-input absolute inset-0 opacity-0 w-full h-full cursor-pointer"
              :class="{ 'ring-2 ring-BlueGem': endTimePickerOpen }"
            />
            <div
              class="w-full pb-2.5 bg-transparent border-b border-BlueChalk text-BluishGrey flex justify-between items-center"
            >
              <span class="text-DarkJungle text-sm font-bold">{{
                formattedEndTime || "Choose time"
              }}</span>
              <img :src="clockIcon" alt="clock icon" class="ml-2 w-5 h-5" />
            </div>
          </div>
        </div>

        <!-- <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >Set a Reminder</label
          >
          <input
            type="text"
            v-model="reminderInterval"
            class="w-full pb-2.5 border-b border-BlueChalk outline-none"
            placeholder="Target time to reminder"
          />
        </div> -->

        <div class="flex flex-col gap-1">
          <label class="text-sm font-medium text-BluishGrey"
            >Set a Reminder</label
          >
          <CustomDropdown v-model="selectedReminder" :options="reminderOptions" />
        </div>
      </div>

      <div class="w-fit flex items-center gap-4 self-center">
        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-transparent border border-ResolutionBlue text-ResolutionBlue rounded-full'"
          @click="closeSetGoalModal"
        >
          Cancel
        </BaseButton>

        <BaseButton
          type="button"
          :classes="'flex items-center justify-center py-3 px-6 bg-ResolutionBlue text-white rounded-full'"
          :disabled="!isFormValid || isCreating"
          @click="handleContinue"
        >
          {{ isCreating ? "..." : "Continue" }}
        </BaseButton>
      </div>
    </section>
  </ModalWrapper>
</template>

<script setup>
import { inject, ref, computed, watch } from "vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { push } from "notivue";
import { parameterMap } from "@/utils/mockData/goals";
import ModalWrapper from "../../testCenter/modals/ModalWrapper.vue";
import BaseButton from "@/components/main/ui/BaseButton.vue";
import InfoIcon from "@/assets/icons/info_outline.svg";
import CustomDropdown from "@/components/main/ui/CustomDropdown.vue";
import calendarGrayIcon from "@/assets/icons/calendar-gray-icon.svg";
import clockIcon from "@/assets/icons/clock.svg";

const props = defineProps({
  selectedGoal: {
    type: Object,
    default: () => ({}),
  },
});

const queryClient = useQueryClient();

const { "user/progressGoalCreate": progressGoalCreate } = mapActions();

// Dropdown states
const selectedAchievement = ref("");
const selectedUnit = ref("");
const currentValue = ref("");
const targetValue = ref("");
// const reminderInterval = ref("");
const selectedReminder = ref("");
// Add date validation
const today = new Date().toISOString().split("T")[0];
const minStartDate = ref(today);
const minEndDate = ref("");

const startDatePickerOpen = ref(false);
const startTimePickerOpen = ref(false);
const endDatePickerOpen = ref(false);
const endTimePickerOpen = ref(false);

const startDatePicker = ref(null);
const startTimePicker = ref(null);
const endDatePicker = ref(null);
const endTimePicker = ref(null);

const startDate = ref("");
const startTime = ref("");
const endDate = ref("");
const endTime = ref("");

const formattedStartDate = ref("");
const formattedStartTime = ref("");
const formattedEndDate = ref("");
const formattedEndTime = ref("");

const isFormValid = computed(() => {
  const dateValid =
    startDate.value &&
    endDate.value &&
    new Date(endDate.value) >= new Date(startDate.value);
  const timeValid = startTime.value && endTime.value;
  const numericValid =
    currentValue.value &&
    targetValue.value &&
    parseFloat(targetValue.value) !== parseFloat(currentValue.value);
  const achievementValid = selectedAchievement.value;
  const reminderValid = selectedReminder.value;

  // Unit only required for non-BMI goals
  const unitValid =
    props.selectedGoal?.name !== "BMI" ? selectedUnit.value : true;

  return (
    dateValid &&
    timeValid &&
    numericValid &&
    achievementValid &&
    unitValid &&
    reminderValid
  );
});

const handleStartDateChange = () => {
  if (startDate.value) {
    const date = new Date(startDate.value);
    formattedStartDate.value = `${date
      .getDate()
      .toString()
      .padStart(2, "0")}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  }
};

const handleStartTimeChange = () => {
  if (startTime.value) {
    const [hours, minutes] = startTime.value.split(":");
    formattedStartTime.value = `${hours}:${minutes}`;
  }
};

const handleEndDateChange = () => {
  if (endDate.value) {
    const date = new Date(endDate.value);
    formattedEndDate.value = `${date.getDate().toString().padStart(2, "0")}-${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date.getFullYear()}`;
  }
};

const handleEndTimeChange = () => {
  if (endTime.value) {
    const [hours, minutes] = endTime.value.split(":");
    formattedEndTime.value = `${hours}:${minutes}`;
  }
};

const achievementOptions = computed(() => [
  { value: "lose", label: `${props.selectedGoal?.name} Loss` },
  { value: "gain", label: `${props.selectedGoal?.name} Gain` },
]);
const reminderOptions = computed(() => [
  { value: 2, label: "2 hours" },
  { value: 4, label: "4 hours" },
  { value: 6, label: "6 hours" },
  { value: 8, label: "8 hours" },
]);

const unitOptions = computed(
  () =>
    props.selectedGoal?.unitOptions?.map((unit) => ({
      value: unit.toLowerCase(),
      label: unit,
    })) || []
);

// Mutation for goal creation
const { mutate: createGoal, isLoading: isCreating } = useMutation({
  mutationFn: async () => {
    const parameter =
      parameterMap[props.selectedGoal.name] ||
      props.selectedGoal.name.toLowerCase().replace(/ /g, "_");

    // Determine the correct unit based on the selected goal
    let unit = "";
    if (props.selectedGoal.name === "Weight") {
      unit = selectedUnit.value.includes("kg") ? "kg" : "lb";
    } else if (props.selectedGoal.name === "Waist Circumference") {
      unit = selectedUnit.value.includes("cm") ? "cm" : "inches";
    } else if (props.selectedGoal.name === "BMI") {
      // BMI has no unit
      unit = "";
    }

    const payload = {
      parameter,
      start_value: parseFloat(currentValue.value),
      target_value: parseFloat(targetValue.value),
      reminder_interval: selectedReminder.value,
      // reminder_interval: parseInt(selectedReminder.value, 10),
      start_date: `${formattedStartDate.value} ${formattedStartTime.value}:00`,
      end_date: `${formattedEndDate.value} ${formattedEndTime.value}:00`,
      goal: selectedAchievement.value,
      ...(unit && { unit }),
    };

    return await progressGoalCreate(payload);
  },
  onSuccess: (response) => {
    push.success("Goal created successfully!");
    const goalId = response.data.id;
    queryClient.invalidateQueries(["progressReports"]);
    if (props.selectedGoal?.name === "BMI") {
      openInputHeightModal(goalId);
    } else {
      openGoalSummaryModal(goalId);
    }
    closeSetGoalModal();
  },
  onError: (error) => {
    push.error(error.response?.data?.message || "Error creating goal");
  },
});

const handleContinue = () => {
  if (!isFormValid.value) return;

  const startDateTime = new Date(`${startDate.value}T${startTime.value}`);
  const endDateTime = new Date(`${endDate.value}T${endTime.value}`);

  if (endDateTime <= startDateTime) {
    push.error("End date/time must be after start date/time");
    return;
  }

  if (parseFloat(targetValue.value) === parseFloat(currentValue.value)) {
    push.error("Target value must be different from current value");
    return;
  }

  createGoal();
};

watch(startDate, handleStartDateChange);
watch(startTime, handleStartTimeChange);
watch(endDate, handleEndDateChange);
watch(endTime, handleEndTimeChange);
// Update startDate watcher
watch(startDate, (newVal) => {
  handleStartDateChange();
  minEndDate.value = newVal;

  // Reset end date if earlier than new start date
  if (endDate.value && new Date(endDate.value) < new Date(newVal)) {
    endDate.value = "";
    formattedEndDate.value = "";
  }
});

// Picker handlers
const openStartDatePicker = () => startDatePicker.value.click();
const openStartTimePicker = () => startTimePicker.value.click();
const openEndDatePicker = () => endDatePicker.value.click();
const openEndTimePicker = () => endTimePicker.value.click();

const closeSetGoalModal = inject("closeSetGoalModal");
const openInputHeightModal = inject("openInputHeightModal");
const openGoalSummaryModal = inject("openGoalSummaryModal");
</script>
