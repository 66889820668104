<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59018 13.8489V11.8104C6.59018 11.2901 7.01511 10.8682 7.53928 10.8682H9.4554C9.70712 10.8682 9.94853 10.9675 10.1265 11.1442C10.3045 11.3209 10.4045 11.5605 10.4045 11.8104V13.8489C10.4029 14.0652 10.4884 14.2733 10.6419 14.4268C10.7954 14.5803 11.0044 14.6667 11.2223 14.6667H12.5295C13.1401 14.6682 13.7262 14.4286 14.1584 14.0005C14.5907 13.5725 14.8337 12.9913 14.8337 12.3852V6.57792C14.8336 6.08832 14.615 5.62391 14.2367 5.30979L9.78967 1.78392C9.0161 1.16572 7.90773 1.18568 7.15725 1.83133L2.81167 5.30979C2.41549 5.61465 2.17869 6.08043 2.16699 6.57792V12.3793C2.16699 13.6426 3.19858 14.6667 4.47111 14.6667H5.74852C6.20114 14.6667 6.56899 14.3042 6.57227 13.8548L6.59018 13.8489Z"
      fill="#FFFFFF"
    />
  </svg>
</template>
