<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
    >
      <LoadingAiResponseView v-if="isPending" />

      <CardiovascularRiskAssessmentResullt v-else-if="data" :data="data.response" />

      <ErrorMessage
        v-else-if="error"
        class="py-44"
        text="Please update your advanced metrics to get predictions"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import LoadingAiResponseView from "@/views/main/patient/ai/LoadingAiResponseView.vue";
import CardiovascularRiskAssessmentResullt from "@/components/main/patient/ai/cardiovascularRiskPrediction/CardiovascularRiskAssessmentResullt.vue";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import { mapActions } from "@/hooks/mapStore";
import { useMutation, useQueryClient } from "@tanstack/vue-query";

const { "ai/getPatientSIUnit": getPatientSIUnit } = mapActions();

const queryClient = useQueryClient();

const { mutate, data, error, isPending } = useMutation({
  mutationFn: getPatientSIUnit,

  onSuccess: (data) => {
    queryClient.invalidateQueries({ queryKey: ["patientSiUnit"] });
  },
});

const handleGetSIUnit = () => {
  mutate();
};

onMounted(() => {
  handleGetSIUnit();
});
</script>
