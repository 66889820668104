<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.9754 16C13.8245 13.8427 12.7267 11.0348 12.6797 8.2135C10.2739 8.47059 8.31526 10.2993 7.89491 12.6802C10.8172 12.6601 13.7485 13.7667 15.9754 16ZM12.6797 8.10395C12.6596 5.18206 13.7664 2.25122 16 0.0245913C13.8424 2.17521 11.0341 3.27288 8.21241 3.31983C8.46954 5.7253 10.2985 7.68367 12.6797 8.10395ZM8.10285 3.31983C5.18055 3.33995 2.2493 2.23334 0.0223608 0C2.17328 2.15733 3.2711 4.96521 3.31806 7.7865C5.72387 7.52941 7.6825 5.70071 8.10285 3.31983ZM3.32029 7.89605C3.34042 10.8179 2.23365 13.7488 0 15.9754C2.15763 13.8248 4.9659 12.7271 7.78759 12.6802C7.53047 10.2747 5.70151 8.31857 3.32029 7.89605Z"
      fill="url(#paint0_linear_284_3085)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_284_3085"
        x1="16"
        y1="8"
        x2="0"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BECDFF" />
        <stop offset="0.5" stop-color="#436EB7" />
        <stop offset="1" stop-color="#FF002C" />
      </linearGradient>
    </defs>
  </svg>
</template>
