<template>
  <div className="w-full md:w-[100%] cursor-pointer">
    <div
      className="flex flex-col w-full overflow-auto scrollbar-hide relative bg-gradient-to-r from-purple-900 via-blue-500 to-red-500"
    >
      <div class="flex space-x-5 relative -top-3">
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-PaleRose via-AztecPurple to-Mirage rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
      </div>

      <div class="flex space-x-5">
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
        <div class="middleSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-ResolutionBlue via-FlatBlue to-Ruddy rounded-[30px]"
          ></div>
        </div>
      </div>

      <div class="flex space-x-5 relative -bottom-3">
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-PaleRose via-AztecPurple to-Mirage rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
        <div class="topSlides">
          <div
            class="w-[50rem] h-14 bg-gradient-to-r from-Mirage via-AztecPurple to-PaleRose rounded-[30px]"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.topSlides {
  animation: scroll_left 30s linear infinite;
}

.middleSlides {
  animation: scroll_right 30s linear infinite reverse;
}

@keyframes scroll_left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1000%);
  }
}

@keyframes scroll_right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1000%);
  }
}
</style>
