<template>
  <main
    class="mx-auto px-2 lg:px-16 w-full min-h-[calc(100vh-80px)] flex flex-col space-y-8"
  >
    <!-- Show Error Message if Health Score Fetching Fails -->
    <ErrorMessage
      v-if="isHealthScoreError"
      text="Failed to load health score. Please try again."
    />
    <!-- Health Score Section -->
    <section v-else class="bg-white p-4 md:p-10 rounded-lg shadow-xl w-full">
      <div
        class="w-full h-full flex flex-col gap-4 p-8 border border-mistGray rounded-lg"
      >
        <!-- Health Score Overview -->
        <div
          class="w-full h-full flex flex-col md:flex-row justify-between items-center gap-4"
        >
          <!-- Circular Health Score -->
          <div
            class="h-[195px] flex items-center gap-5 py-4 px-6 rounded-xl w-full md:w-1/2"
            :class="healthScoreBg"
          >
            <div
              class="relative w-[114px] h-[114px] flex items-center justify-center"
            >
              <svg class="absolute w-full h-full" viewBox="0 0 36 36">
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="none"
                  stroke="#E5E7EB"
                  stroke-width="2"
                />
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="none"
                  :stroke="healthScoreColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-dasharray="100 100"
                  :style="{
                    strokeDashoffset: `${100 - healthScorePercentage}`,
                    transition: 'stroke-dashoffset 0.8s ease-in-out',
                  }"
                  transform="rotate(-90 18 18)"
                />
              </svg>
              <span
                class="absolute text-2xl font-bold"
                :class="healthScoreText"
              >
                {{ healthScore ?? 0 }}/100
              </span>
            </div>
            <div class="max-w-[324px] space-y-[27px] text-DavyGrey text-base">
              <h2 class="font-bold">Overall Health Score</h2>
              <p class="font-normal">
                Some vitals are within a healthy range, while others need slight
                improvements.
              </p>
            </div>
          </div>

          <!-- Score Legend -->
          <div class="w-full h-full md:w-1/2 flex item-start">
            <div class="w-2.5 h-[195px] bg-ResolutionBlue rounded-l-xl"></div>
            <div
              class="flex flex-col gap-4 py-[18px] px-9 border border-lightBlue rounded-r-xl w-full"
            >
              <h3 class="text-DavyGrey text-base font-bold">
                Overall color-coded indicator
              </h3>
              <ul class="text-sm text-DavyGrey space-y-3">
                <li class="flex items-center gap-6">
                  <span class="w-4 h-4 bg-neonGreen rounded-full"></span>
                  (80-100) Excellent
                </li>
                <li class="flex items-center gap-6">
                  <span class="w-4 h-4 bg-PendingYellow rounded-full"></span>
                  (60-79) Good
                </li>
                <li class="flex items-center gap-6">
                  <span class="w-4 h-4 bg-fadedOrange rounded-full"></span>
                  (40-59) Fair
                </li>
                <li class="flex items-center gap-6">
                  <span class="w-4 h-4 bg-ArtyClickRed rounded-full"></span>
                  Needs Improvement
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Health Metrics Grid -->
        <div
          class="w-full py-8 px-10 grid grid-cols-3 md:grid-cols-6 gap-10 bg-cloudBlue rounded-xl"
        >
          <router-link
            v-for="(metric, index) in healthMetrics"
            :key="index"
            :to="{
              path: '/patient/blood-glucose',
              query: { testType: metric.testType },
            }"
            class="flex flex-col items-center gap-6"
          >
            <div
              class="relative w-[45px] h-[45px] flex items-center justify-center"
            >
              <svg class="absolute w-full h-full" viewBox="0 0 36 36">
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="none"
                  stroke="#F9FBFF"
                  stroke-width="4"
                />
                <circle
                  cx="18"
                  cy="18"
                  r="15.9155"
                  fill="none"
                  stroke="#011D7E"
                  stroke-width="4"
                  stroke-linecap="round"
                  stroke-dasharray="100 100"
                  :style="{
                    strokeDashoffset: `${100 - metric.percentage}`,
                    transition: 'stroke-dashoffset 0.8s ease-in-out',
                  }"
                  transform="rotate(-90 18 18)"
                />
              </svg>
              <span class="absolute text-sm font-bold text-DarkJungle">
                {{ metric.value !== null ? metric.value : "-" }}
              </span>
            </div>
            <p class="text-sm font-bold text-DarkJungle">
              {{ metric.label }}
            </p>
          </router-link>
        </div>

        <!-- Health Score Note -->
        <div
          class="w-full h-full flex flex-col gap-4 p-8 border border-mistGray rounded-lg"
        >
          <div
            class="w-full py-[30px] px-5 flex flex-col gap-4 bg-cloudBlue rounded-xl relative"
          >
            <div class="flex items-center gap-2 relative">
              <h3 class="text-lg font-bold text-DarkJungleGreen">
                Health Score Note
              </h3>
              <div class="relative group">
                <img :src="InfoIcon" alt="Info Icon" class="cursor-pointer" />
                <div
                  class="absolute left-[110%] top-1/2 transform -translate-y-1/2 bg-blue-900 text-white text-sm rounded-lg p-3 w-[500px] shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 before:content-[''] before:absolute before:-left-2 before:top-1/2 before:transform before:-translate-y-1/2 before:border-8 before:border-transparent before:border-r-blue-900 after:content-[''] after:absolute after:-bottom-2 after:left-3 after:border-8 after:border-transparent after:border-t-blue-900"
                >
                  Your health score is a summary of your overall well-being,
                  calculated based on the above displayed vitals. Regular
                  check-ins with a doctor will help you stay in health.
                </div>
              </div>
            </div>
            <p class="text-sm text-DarkJungleGreen font-normal">
              Your health score is a summary of your overall well-being,
              calculated based on your vitals: weight, blood glucose, blood
              pressure, heart rate, and lung capacity. You're in good health,
              with steady progress in key areas. Keep up the good work! Regular
              check-ins will help you stay on track.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script setup>
import { computed, watchEffect, ref } from "vue";
import { mapGetters, mapActions } from "@/hooks/mapStore";
import { useQuery } from "@tanstack/vue-query";
import ErrorMessage from "@/components/main/ui/ErrorMessage.vue";
import InfoIcon from "@/assets/icons/info_outline.svg";

const { "user/fetchPatientHealthScore": fetchPatientHealthScore } =
  mapActions();

// Fetching the patient health score
const {
  data: healthScoreData,
  isLoading: isHealthScoreLoading,
  isError: isHealthScoreError,
} = useQuery({
  queryKey: ["patientHealthScore"],
  queryFn: fetchPatientHealthScore,
  onSuccess: (data) => {},
});

//  Ensure healthScore updates reactively
const healthScore = computed(() => {
  return healthScoreData.value?.data?.Total_Health_Score ?? 0;
});

// Fix healthScore percentage calculation
const healthScorePercentage = computed(() => {
  return healthScore.value !== null && !isNaN(healthScore.value)
    ? (healthScore.value / 100) * 100
    : 0;
});

// Fix color updates
const healthScoreColor = computed(() => {
  if (healthScore.value >= 80) return "#34C759"; // Excellent
  if (healthScore.value >= 60) return "#F0D60B"; // Good
  if (healthScore.value >= 40) return "#FFA900"; // Fair
  return "#ff0000"; // Needs Improvement
});

const healthScoreText = computed(() => {
  if (healthScore.value >= 80) return "text-neonGreen";
  if (healthScore.value >= 60) return "text-neonYellow";
  if (healthScore.value >= 40) return "text-neonOrange";
  return "text-ArtyClickRed";
});
const healthScoreBg = computed(() => {
  if (healthScore.value >= 80) return "bg-softLime";
  if (healthScore.value >= 60) return "bg-softYellow";
  if (healthScore.value >= 40) return "bg-PendingYellow";
  return "bg-lighterOrange";
});

// Health Metrics Data
const healthMetrics = computed(() => {
  const healthData = healthScoreData?.value?.data || {};

  return [
    {
      label: "Blood Glucose",
      value: healthData["Glucose_Score"] ?? 0,
      percentage: healthData["Glucose_Score"] ?? 0,
      testType: "glucose",
    },
    {
      label: "SpO2",
      value: healthData["SpO2_Score"] ?? 0,
      percentage: healthData["SpO2_Score"] ?? 0,
      testType: "SpO2",
    },
    {
      label: "Blood Pressure (Systolic)",
      value: healthData["Blood_Pressure_Systolic_Score"] ?? 0,
      percentage: healthData["Blood_Pressure_Systolic_Score"] ?? 0,
      testType: "blood_pressure",
    },
    {
      label: "Blood Pressure (Diastolic)",
      value: healthData["Blood_Pressure_Diastolic_Score"] ?? 0,
      percentage: healthData["Blood_Pressure_Diastolic_Score"] ?? 0,
      testType: "blood_pressure",
    },
    {
      label: "Widal Test",
      value: healthData["Widal_Score"] ?? 0,
      percentage: healthData["Widal_Score"] ?? 0,
      testType: "typhoid",
    },
    {
      label: "Voluntary Serology",
      value: healthData["Voluntary_Serology_Score"] ?? 0,
      percentage: healthData["Voluntary_Serology_Score"] ?? 0,
      testType: "hiv",
    },
    {
      label: "Weight",
      value: healthData["Weight_Score"] ?? 0,
      percentage: healthData["Weight_Score"] ?? 0,
      testType: "weight",
    },
    {
      label: "Temperature",
      value: healthData["Temperature_Score"] ?? 0,
      percentage: healthData["Temperature_Score"] ?? 0,
      testType: "temperature",
    },
    {
      label: "ECG",
      value: healthData["ECG_Score"] ?? 0,
      percentage: healthData["ECG_Score"] ?? 0,
      testType: "ecg",
    },
    {
      label: "Malaria",
      value: healthData["Malaria_Score"] ?? 0,
      percentage: healthData["Malaria_Score"] ?? 0,
      testType: "malaria",
    },
    {
      label: "Hepatitis B",
      value: healthData["Hepatitis_B_Score"] ?? 0,
      percentage: healthData["Hepatitis_B_Score"] ?? 0,
      testType: "hepatitis",
    },
  ];
});
</script>

<style scoped>
.tooltip {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>
